@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.woff2') format('woff2'),
      url('./fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Banff';
  src: url('./fonts/BanffPlain.woff2') format('woff2'),
      url('./fonts/BanffPlain.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body{
  font-family: 'Roboto' !important;
}
.font{
  font-family: 'Banff' !important;
}
