@media (max-width: 1199.98px) { 
.back_width {
  width: 100% !important;
  padding: 0 0px;
}
.cart-bg {
  background: #fff;
}
.mycart {
  padding: 6px 6px;
}
.payment-option h1 {
  padding: 8px 0 12px 0;
  margin: 0 0 14px 0;
}
.flip-card {
  height:18vw;
}
.book-section img {
  height: auto;
}
.imgpay img {
  max-width:50%;
  margin: 3px 0 12px 0;
}
.customer-review {
  width: 100%;
  margin: 12px auto;
}
progress {
  width:80px;
}
/* .pdf-img {
  right: 0%;
} */
}
@media (max-width: 991.98px) { 
  .back_width {
    width: 100% !important;
    padding: 0 0px;
  }
  .cart-bg {
    background: #fff;
  }
  .mycart {
    padding: 6px 6px;
  }
.payment-option h1 {
  font-size: 22px;
  padding: 8px 0 12px 0;
  margin: 0 0 14px 0;
}
.imgpay img {
  max-width:60%;
  margin: 3px 0 12px 0;
}
.customer-review {
  width: 100%;
  margin: 12px auto;
}
progress {
  width:80px;
}
.book-section img {
  height: auto;
}
.zoom_mainImg__L9qZa {
  width: 230px !important;
  margin: 4px 0 3px 0;
}
.heart {
  width: 50px;
  height: 45px;
  margin: 4px 6px;
}
/* cart popup */
.zoom_mainImg__xbZmi {
  max-width: 100%;
}
.cart_popup {
  width:400px;
  height: 100%;
  overflow-y: scroll;
}
.rempty-cart {
  text-align: right;
}
.right-number {
  width: 85px;
}
.cart_total {
  font-size: 16px;
  margin: 0 10px;
}
.right-foot {
  position: inherit;
}
.right-price {
font-size: 14px;
}
/* .pdf-img {
  right: -32%;
} */
/* cart popup */
/* news events */
.news-box {
  margin:6px 0;
}
.flip-card {
  height:18vw;
}
.news-img-section img {
  height:180px;
}
.fix-wh {
  height:auto;
}
.blur-bg {
  padding:8px 0;
  font-size: 12px;
  width: 100%;
}
.catalogue-box {
  display: block;
  text-align: center;
}
.catalogue_Box1 {
  display: inline-block;
  margin: 8px 6px;
}
.cat-small {
  margin: 3px 0 6px 0;
  height: auto;
}
.ebookPopup p {
  padding:8px 0;
  font-size: 16px;
}
/* news events */
}
@media (max-width: 767.98px) { 
/* book */
.books-bg h1 {
    font-size:24px;
    }
.books-bg {
    padding:28px 0;
    margin:6px 0 3px 0;
}
.book-section img {
  height: auto;
}
.align-book {
    text-align: center;
}
.abo-img {
    width:95%;
    padding: 0px 0;
    display: none;
}
.cat-small {
  font-size: 16px;
  margin: 10px 0 10px 0;
  height: auto;
}
.book_cbse button {
  margin: 12px auto;
  padding: 8px 2px;
  font-size: 15px;
  width: 80%;
}
.book-img-btn {
    margin: 2px 0 0px 0;
  }
.book-section img {
  width: 100%;
}
.form-wrap {
  width: 100%;
}
#warp-login {
margin: 12px 12px;
}  
/*login  */
.feedback_form {
  width: 95%;
  margin:16px auto;
}
/* login */
/* cart */
.back_width {
  width: 100% !important;
  padding: 0 0px;
}
.cart-bg {
  background: #fff;
}
.mycart {
  padding: 6px 6px;
}
.payment-option h1 {
  font-size: 20px;
  padding: 8px 0 12px 0;
  margin: 0 0 14px 0;
}
.imgpay img {
  max-width: 85%;
  margin: 3px 0 12px 0;
}
.bookshop{
  padding:10px;
}
.customer-review {
  width: 100%;
  margin: 12px auto;
}
progress {
  width: 58px;
}
/* cart */
/* news events */
.news-box {
  margin:6px 0;
}
.flip-card {
  height:32vw;
}
.fix-wh {
  height:auto;
}
.blur-bg {
  padding:8px 0;
  font-size: 12px;
  width: 100%;
}
/* news events */
/* cart popup */
.cart_popup {
  width: 320px;
  height: 100%;
  overflow-y: scroll;
}
.rempty-cart {
  text-align: right;
}
.right-number {
  width: 85px;
}
.cart_total {
  font-size: 16px;
  margin: 0 10px;
}
.right-foot {
  position: inherit;
}
.right-price {
font-size: 14px;
}

/* Principal Biography */
.bio-btn{
  float: right;
  position: inherit;
}
.biography {
  display: flex;
  margin: 0 auto;
}
.box-shadow {
  padding: 6px 12px;
  margin: 6px 0 6px 0;
}
.bioImg {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.ebookPopup p {
  padding: 6px 0;
  font-size: 16px;
}
/* Principal Biography */

/* .pdf-img {
  right:28%;
} */
/* cart popup */
  }

@media (max-width: 575.98px) { 
/* about us */
.about p {
  font-size: 14px;
  margin:3px 6px;
  text-align: justify !important;
}
.about {
  padding: 0px 0;
  margin: 6px 0 6px 0;
}
.form-wrap {
  width: 100%;
  margin:12px auto;
}
.sign_bg {
  margin: 1% 0%;
}
.sign_up {
  margin: 2% 3% 2% 3%;
  padding: 1% 3%;
}
.cat-small {
  font-size: 16px;
  margin:5px 0 5px 0;
  height: auto;
}
.about-bg {
    padding: 19px 0;
}
.abo-hedi {
  font-size: 22px;
  padding: 6px 0;
  margin: 3px 0 4px 0;
}
.about1 {
  padding: 6px 0;
  line-height: 28px;
}
.abo-foot-bg img {
  max-width: 60px;
  margin: 6px auto;
}
.abo-foot-bg {
    padding:8px 0;
    margin: 8px 0px;
}
.abo-foot-bg h4 {
  font-size: 18px;
}
.abo-foot-bg h5 {
  font-size: 22px;
}
.contact-us h1 {
  font-size: 24px;
  padding: 3px 0;
  margin: 6px 0 6px 0;
}
.sign-btn {
  padding: 8px 12px;
  font-size: 14px;
  margin: 3px 0 10px 0;
}
/* about us */

/* book */
.books-bg h1 {
  font-size: 20px;
  }
.books-bg {
  padding:28px 0;
  margin:6px 0 3px 0;
}
.align-book {
  text-align: center;
}
.abo-img {
  width: 50%;
  padding: 0px 0;
}
.book_cbse button {
  margin:6px auto;
  font-size: 15px;
}
.book-img-btn {
  margin:15px 0 0px 0;
}
/* book */
/* cbse */
.board-from {
  margin: 12px 0 12px 0;
}
.over-text {
  font-size: 15px;
}
.book-dis {
  font-size: 14px;
  padding: 4px 0;
}
.breadcrumb {
  display: flex;
  justify-content: center;
  margin:6px 0;
}
.walls img {
  margin: 2px 0 8px 0;
}
.walls .price {
  margin: 8px 0;
}
.cart-width {
  font-size: 15px;
  width: 94%;
  margin: 5px 8px;
  padding: 9px 0;
}
.buyNow{
  font-size: 15px;
  width: 94%;
  margin: 5px 8px;
  padding: 9px 0;
}
.heart {
  width: 42px;
  height: 42px;
  margin: 4px 6px;
}
.modal.right .modal-dialog {
  width: 320px;
}
.right-top-hedi {
  padding: 6px 22px;
}
.right-foot h2 {
  font-size: 22px;
}
.right-foot h3 {
  font-size: 22px;
}
.walls h1 {
  font-size:18px;
  margin:3px 0;
}
.book-section img {
  height: auto;
}
.walls-btn > span {
  padding: 6px 2px;
  font-size: 14px;
  margin: 6px 4px 6px 4px;
  width: 134px;
}
.box-bold {
  font-size: 15px;
}
/* cbse */
/* contact us */
.contact-us {
  margin: 6px 0;
}
.contact-us img {
  max-width: 58%;
  margin: 0 auto;
}
.contact-us p {
  font-size: 14px;
}
.branch-bg {
  margin: 4px 0;
  padding: 3px 0;
}
.add-from {
  margin: 3px 0;
  font-size: 15px;
}
address {
  margin:16px 0;
}
.add-from label {
  font-size: 15px;
  margin: 6px 0 0px 0;
}
.contact-btn {
  padding:6px 32px;
  font-size: 15px;
  margin:10px 0 8px 0;
}
.Off-branch {
  margin: 0px 0 4px 0;
}
.accordion {
  margin: 6px 0;
}
/* contact us */
/* Login */
.form-wrap {
  width: 95%;
  margin: 10px auto;
}
.login-mar {
  padding: 6px 12px !important;
  margin: 4px 0 2px 0;
}
.login-hedi {
  margin: 12px 0 3px 0;
  font-size: 16px;
}
.form-distributor {
  width: 100%;
}
#form-dis {
  margin: 6px 16px;
}
.back_width {
  width: 98% !important;
}
/* Login */
/* cart */
.back_width {
  width: 100% !important;
  padding: 0 0px;
}
.cart-bg {
  background: #fff;
}
.mycart {
  padding: 6px 6px;
}
.payment-option h1 {
  font-size: 20px;
  padding: 8px 0 12px 0;
  margin: 0 0 14px 0;
}
.imgpay img {
  max-width: 85%;
  margin: 3px 0 12px 0;
}
.view-cart {
  font-size: 16px;
  width: 98%;
  padding: 10px 0;
  margin: 6px 0 0px 0;
}
.shoping_btn {
    padding: 9px 2px;
    font-size: 14px;
    margin: 6px 0px 6px 0;
    width: 98%;
}
/* cart */

/* order list */
.order-list {
  margin: 10px auto;
  padding: 0 12px;
}
.profile_btn {
  display: block;
  text-align: center;
}
.profile_btn a {
  padding: 8px 2px;
  font-size: 15px;
  margin:6px 6px;
  width: 135px;
}
.user_address_from {
  margin:4px 0;
  padding: 6px 16px;
}
.user_address_from h2 {
  font-size: 19px;
  margin: 4px 0;
  padding: 6px 0;
}
.add-address-btn {
  padding: 8px 12px;
  font-size: 9px;
  font-weight: bold;
}
.order-hedi {
  font-size: 18px;
}
.shipping-add-box {
  margin: 8px 0 8px 0;
  padding: 6px 12px;
}
.main-order-box img {
  margin: 0 22px;
  width: 100% !important;
}
.order-light {
  margin: 3px 0;
  text-align: center;
}
.tracking-detail {
  width: 98%;
  margin: 6px auto;
}
.login-main {
  padding: 8px 0;
  font-size: 16px;
}
/* order list */

/* bookshop */
.bookshop{
  padding:10px;
}
.bookshop h1 {
  font-size: 20px;
  margin: 8px 0 10px 0;
}
.bookshop-panel {
  font-size: 16px;
}
.bookfa {
  margin: 6px auto;
  display: block;
}
.add {
  font-size: 16px;
  display: block;
  text-align: center;
  margin: 2px 0;
}
.bohedi {
  font-size: 16px;
  margin: 4px 0;
}
.customer-review {
  width: 100%;
  margin: 12px auto;
}
progress {
  width: 58px;
}
/* bookshop */
/* news events */
.news-box {
  height:auto;
  margin:6px 0;
}
.flip-card {
  height: 65vw;
}
.news-img-section img {
  height: auto;
}
.fix-wh {
  max-height:24vh;
}
.blur-bg {
  padding:8px 0;
  font-size: 12px;
  width: 100%;
}

/* news events */
/* cart popup */
.cart_popup {
  width: 320px;
  height: 100%;
  overflow-y: scroll;
}
.rempty-cart {
  text-align: right;
}
.right-number {
  width: 85px;
}
.cart_total {
  font-size: 16px;
  margin: 0 10px;
}
.right-foot {
  position: inherit;
}
.right-price {
font-size: 14px;
}
/* .pdf-img {
  right: -19%;
} */
/* cart popup */
/* invi-authors  */
.invitation-form {
  margin: 8px 4px;
  padding: 6px 6px;
}
.box-invi {
  padding: 5px 6px;
  margin: 3px;
  text-align: center;
}
.box-invi p {
  line-height: 22px;
  text-align: justify;
}
.condition-box {
  margin: 3px 0px;
  padding: 2px 3px;
}
.condition-box p {
  font-size: 15px;
  padding: 6px 6px;
  margin: 6px 3px;
  line-height:20px;
}
.discl_bg h1 {
  font-size: 20px;
  padding: 3px 0;
  margin: 10px 0 14px 0;
}
.discl_bg h2 {
  display: none;
}
.discl_bg {
  padding: 0px 10px;
}
.faqs {
  padding: 6px 3px;
  margin: 3px 3px;
}
.accordion-button {
  padding: 6px 6px !important;
}
.order-from {
  margin: 0 2px;
  padding: 3px;
}
.togrther-box {
  margin:15px;
  padding: 15px 12px;
  border-radius: 22px;
}
.togrther-btn {
  font-size: 14px;
}
.sitemap {
  margin: 3px 0;
}
.sitemap h1 {
  margin: 9px 25px;
  font-size: 22px;
}
#primaryNav.col6 li {
  width: 49.2% !important;
}
#primaryNav li a {
  margin: 0 6px 0 0 !important;
  padding: 6px 0 !important;
}
/* invi-authors  */
/* Principal Biography */
.bio-btn{
  float: right;
  position: inherit;
}
.biography {
  display: flex;
  margin: 0 auto;
}
.box-shadow {
  padding: 6px 12px;
  margin: 6px 0 6px 0;
}
.bioImg {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
/* Principal Biography */
.chat-hedi {
  font-size: 20px;
  margin: 0 0 4px 0;
}
.chat-popup {
 padding: 0;
}
.ebookPopup p {
  padding: 6px 0;
  font-size: 15px;
}

/* popup button home */
.popup-btn-left {
  border:0px solid #87ed44;
  bottom: 11%;
  padding: 10px;
}
.popup-btn-right {
  border: 0px solid #87ed44;
  bottom: 11%;
  padding: 10px;
}
/* popup button home */

}

