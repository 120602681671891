body {
	margin: 0;
	padding: 0;
	background: #fff;
	overflow-x: hidden;
    font-family: 'Roboto' !important;
}
.aos-animate {
	overflow: hidden !important;
}
.back_width {
    width: 90% !important;
    padding: 0 6px;
}
.top-bar {
	background:#ed3237;
	text-align: center;
	padding:1px 0;
}
.top-bar .overlay {
    display: none !important;
  }
.chatsho {
    font-size: 20px;
    display: inline-block;
    color: #ed3237;
  }
.right-login {
    text-align: right;
  }
.top-bold {
    font-weight:600;
}
.top-bar p {
	color: #fff;
	font-size: 15px;
	margin:4px 4px;
}
.logo-section {
    width: 96%;
    margin: 8px auto 9px auto;
    padding: 0 4px;
  }
.logo img {
	max-width: 100%;
	margin: 15px 0 0 0;
}
#dropdown-basic {
    background: #fff;
    padding: 4px 16px;
    border: 2px solid #fff;
    font-size: 16px;
    color: #333;
    margin: 0 12px 0 0;
    border-radius: 1px;
    transition: all .3s ease 0s;
    outline: none !important;
  }
  #dropdown-basic:hover {
    background: #fff;
    color: #282828;
  }
.dropdown-item:active {
    color: #262626;
    text-decoration: none;
    background-color: #ffffff;
}
.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5) !important;
}
.btn-primary:active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #ffffff;
    border-color: #ffffff;
}
.btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #ffffff !important;
  }
.login {
    background: #fff;
    padding: 6px 18px;
    border: 2px solid #ed3237;
    font-size: 16px;
    color: #ed3237;
    margin: 0 6px;
    border-radius: 1px;
    transition: all .3s ease 0s;
}
.login:hover {
	background: #ed3237;
	color: #fff;
}
.favi {
	font-size:18px !important;
}
.search {
    background: #eb6865;
    color: #fff;
	padding: 5px 9px;
	border-radius: 50px;
	font-size: 14px;
	display: inline-block;
	transition: all .3s ease 0s;
	cursor: pointer;
}
.cart {
    margin: 5px 12px;
    position: relative;
    border: 1px solid #d7d7d7;
    display: inline-block;
    padding:5px 12px;
    box-shadow: 0 12px 6px -13px #1f1f1f;
  }
  .cartcount {
    position: absolute;
    bottom: 26px;
    background: #393939;
    padding: 2px 0px;
    color: #fff;
    border-radius: 50%;
    left: 26px;
    font-size: 14px;
    display: none;
    width: 26px;
    text-align: center;
    z-index: 999;
}
/*navbar*/
.navigation {
    display: flex;
    justify-content: center;
    margin:10px 0 0 0 !important;
}
.navbar {
    padding: 0 0 !important;
}
.navbar-nav li {
    font-size: 16px;
    margin: 0 13px 0 13px;
}
.navbar-nav a {
    color: #000;
    text-decoration: none;
    padding:8px 6px;
    transition:transform .3s;
  }
.navbar-nav a:hover {
    border-bottom: 2px solid #e8504d;
	color: #000;
}
.nav-item.dropdown a {
    padding: 0 6px;
    transition: transform 0.3s;
    color: #000 !important;
    background: #fff;
    border: none;
}
.border-bootem{
    border-bottom: 1px solid #d3d3d3 !important;
}
/*navigation*/
/*banner*/
.banner {
	margin:8px 0 0 0;
	text-align: center;
}
.banner img {
   width: 100%;
}
.carousel-indicators [data-bs-target] {
  width: 9px !important;
  height: 9px !important;
  background-color: rgb(0, 0, 0) !important;
  border-radius: 50% !important;
}
.carousel-indicators .active {
    opacity: 0.9 !important;
    color: #fff !important;
    background-color: #FFF !important;
  }
.carousel-indicators {
    bottom: -23px !important;
  }
.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 40% !important;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2% !important;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
    background: #e92e2a !important;
    height: 7% !important;
  }
.carousel-control-prev {
    left:8px !important;
}
.carousel-control-next {
    right:8px!important;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    width:25px !important;
    height:25px !important;
  }
  .owl-next:hover{
    opacity: 0.7;
  }
  .owl-prev:hover{
    opacity: 0.7;
  }
.student-parents {
    color: #fff;
	padding:0px 0;
	margin: 0 0;
	position: relative;
}
.carousel-caption {
    padding-bottom: 0.25rem !important;
}
.bg-img {
    background-image: url(../images/bg.jpg);
    /* background-repeat: no-repeat;
    background-size: cover; */
    /* color: #fff;
    border-bottom:6px solid #141414;
	padding:0px 0;
	margin: 0 0 26px 0;
	position: relative; */
}
/* .stu-par {
    margin: 4px 0 0 0;
    text-align: center;
} */
.stu-par img {
	max-width: 100%;
}
.btn_center {
    position: absolute;
    top: 33%;
    width:64%;
}
.username {
    margin: 0 12px 0;
  }
.logo-section a {
    color: #333;
}
.logo-section a:hover {
    color: #333;
}
.student-parents-btn {
    background: #fff;
    border: 2px solid #ef353a;
    color: #000;    
    font-size: 16px;
    padding:12px 4px;
    border-radius:50px;
    width: 23%;
    margin:6px 6px;
    transition: all .3s ease 0s;
}
.student-parents-btn:hover {
    background: #ef353a;
    border:2px solid #ef353a;
    color: #fff;
}
/*New Releases*/
.main_slider {
    background: #fff;
    padding: 15px 2px;
    margin: 10px auto;
    width: 82%;
  }
.main_slider_1{
    padding: 15px 2px;
    margin: 10px auto;
    width: 82%;
}
.new-release {
    text-align: center;
}
.rel-hedi {
    background: #ed3237f7;
    color: #fff;
    font-weight: 500;
    margin: 0;
    font-size:25px;
    padding:7px 3px;
    text-shadow: 1px 2px 2px #333;  
  }
  .rel-hedi-1 {
    background: #ed3237f7;
    color: #fff;
    font-weight: 500;
    margin: 0 0 22px 0;
    font-size: 25px;
    padding:7px 3px;
    text-shadow: 1px 2px 2px #333;
  }
/*New Releases*/
/* slider Releases */
.owl-carousel .owl-item img {
    max-width:85%;
    margin: 0 auto;
}
.item p {
    text-align: center;
    margin: 12px 5px;
    font-size: 16px;
    color: #000;
    min-height:50px;
}
.owl-theme .owl-controls{
    width: 100%;
    position: absolute;
    top:34%;
}
.owl-theme .owl-controls .owl-buttons div {
    width: 36px;
    height: 36px;
    line-height: 32px;
    border-radius: 2px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.38);
    background: #fff;
    color: #fb3f44;
    padding: 0;
    opacity: 1;
    transition: all .3s ease 0s;
}
.owl-theme .owl-controls .owl-buttons div:hover{
    background:#f7393f;
	color: #fff !important;
}
.owl-prev,.owl-next{
    position: absolute;
    left:-1%;
}
.owl-next{
    left: auto;
    right:-1%;
}
.owl-prev:before, .owl-next:before{
    content: "\00AB";
    font-family: "Font Awesome 4 Free";
    font-weight: 900;
    font-size:23px;
}
.owl-next:before{ 
    content: "\00BB";
 }
 .item iframe {
    width: 100%;
    padding: 0 14px;
  }
.event_iframe iframe {
    width: 100%;
    padding:0;
    margin:4px 0;
  }
.event_iframe p {
    font-size: 15px;
    text-align: center;
  }
.item-radius {
    border-radius:50%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.53);
    height:190px;
    width:190px;
    margin:10px auto;
    padding:23px 10px;
    background: #fff;
}
.item-radius img:hover {
	-webkit-filter: sepia(100%);
	filter: sepia(100%);
}
.item-radius img {
    max-width:85% !important;
  }
.awards p {
   font-size: 15px !important;
   margin:6px 6px;
   color: #000;
  }
/* slider Releases */
/*Shop board*/
.shop-board {
	text-align: center;
}
.shop-img img {
    width: 100%;
    margin: 30px 0;
  }
.shop-box {
    text-align: center;
    padding:12px 0;
    margin: 4px 8px;
    height: 98%;
    background:#ffffff9e;
}
.shop-box img {
	max-width: 100%;
}
.shop-box1 img {
	max-width: 100%;
}
.shop-hedi {
	color: #000000;
	margin: 25px 0 15px 0;
	font-size:18px;
}
.shop-box button {
    background: #ed3237;
    padding: 6px 19px;
    border: 2px solid #ed3237;
    font-size: 15px;
    color: #fff;
    margin: 9px 0 14px 0px;
    transition: all .5s ease;
}
.shop-box button:hover {
	background: #fff;
    color: #000;
}
.shop-box1 {
    text-align: center;
    padding:12px 0;
    margin: 4px 8px;
    height: 98%;
  }
.shop-hedi1 {
	color: #000;
    margin: 25px 0 15px 0;
    font-size:18px;
}
.shop-box1 button {
	background: none;
	padding:6px 20px;
    border: 2px solid #464646;
    background: #fff;
    font-size: 15px;
    color: #000;
	margin:9px 0 14px 0px;
	transition: all .5s ease;
}
.shop-box1 button:hover {
    background: #ed3237;
    border: 2px solid #ed3237;
	color: #fff;
}
/*Shop board*/
/*about index*/
.abo {
	margin: 34px 0;
	text-align: center;
}
.abo img {
    max-width:100%;
}
.about-hedi {
    font-weight:500;
    font-size:32px;
	margin:10px 0;
}
.abo p {
    font-size: 16px;
    line-height: 30px;
    margin: 3px 0 8px 0;
    color: #000;
    font-weight: 500;
    text-align: justify;
}
  .abo-button a {
    border: 1px solid #eb6865;
    border-radius: 50px;
    box-shadow: 0 1px 2px #b4b4b4e0;
    color: #eb6865;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    margin: 12px auto;
    padding:8px 22px;
    text-align: center;
    text-decoration: none;
    transition: all .5s ease;
}
.abo-button a:hover {
    background: #eb6865;
	color: #fff;
	border: 1px solid #eb6865;
}
/*about index*/
/*our authors*/
.our-authors {
	text-align: center;
}
.our-authors figure {
    position: relative;
    overflow: hidden;
    width:42%;
    margin:12px auto;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 3px rgb(0 0 0 / 75%);
}
.our-authors figure img {
	width: 100%;
}
.title {
	font-size: 20px;
    color:#ff1b21;
	margin: 6px 0;
}
.author p {
	font-size: 16px;
	color: #000;
    padding: 0 10px;
}
/*our authors*/
/*associates*/
.associates {
	text-align: center;
}
.asso_ciates img {
    max-width: 100% !important;
    margin:12px 0 34px 0;
  }
.associates_btn a {
    border: 2px solid #ed3237;
    cursor: pointer;
    margin: 8px 0 26px 0;
    border-radius: 100px;
    /* box-shadow: 0px 1px 2px #b4b4b4e0; */
    transition: all .5s ease;
    display: inline-block;
    padding: 9px 24px;
    text-decoration: none;
    color: #000;
    font-size: 15px;
    text-align: center;
    background: #fff;
}
.associates_btn a:hover {
    background:#ed3237;
	color: #fff;
	/* border: 1px solid #ed3237; */
}
/*associates*/
/*Meet Team*/
.meet_taam {
    text-align: center;
}
.meet_taam p {
    margin: 0;
  }
.core-team {
	background: #f5f5f5;
	text-align: center;
	padding: 32px 0;
}
.core-hedi {
	font-weight: 500;
	color: #3e3e3e;
	font-size: 42px;
	line-height: 1.2;
	letter-spacing: 0px;
}
.grid {
	max-width:100%;
	overflow:hidden;
}
.grid99 figure {
    position: relative;
    overflow: hidden;
    width:210px;
    margin: 6px auto;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 3px rgb(0 0 0 / 75%);
}
.grid figure {
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 3px rgba(0,0,0,.75);
    margin: 6px auto;
    overflow: hidden;
    position: relative;
    width: 150px;
  }
.grid figure img {
	max-width: 100%;
}
figure.effect-apollo img {
	opacity: 0.95;
	transition: opacity 0.35s, transform 0.35s;
	transform: scale3d(1.05, 1.05, 1);
    overflow: hidden;
}
figure.effect-apollo figcaption::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.5);
	content: '';
	-webkit-transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	-webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
	transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
}
figure.effect-apollo:hover img {
	opacity: 0.6;
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}
figure.effect-apollo:hover figcaption::before {
	-webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
	transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
}
.core h2 {
	font-size: 22px;
	font-weight: 500;
	color: #000;
	margin: 8px 0;
}
.core h5 {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin:8px 0;
    text-shadow: 2px 4px 3px #fff;
}
/*Meet Team*/
/*Reasons follow us*/
.reasons-us {
	text-align: center;
    padding: 0 0;	
}
.reasons-bg {
    background: rgb(0 0 0 / 68%);
    /* background:#ed3237f2; */
    padding: 12px 0;
    height: 99%;
    margin:2% auto;
    transition: all .3s ease-in-out;
    cursor: pointer;
    width: 84%;
}
.reasons-bg img {
    max-width:78px;
    margin: 10px 0;
  }
.reasons-bg:hover {
    background:#ed3237f2;
	transform: scale(1.1);
}
.reasons-hedi {
    font-weight:500;
    font-size:26px;
    margin: 8px 0 38px 0;
    border-bottom: 2px solid #5e5e5eeb;
    display: inline-block;
    padding: 0px 0 8px 0;
}
.reasons-bg h4 {
    font-size: 19px;
    color: #fff;
    margin: 10px 0;
}
.reasons-bg h5 {
    font-size:24px;
    color: #fff;
    font-weight: 600;
    margin:10px 0;
    text-shadow: 1px 1px 2px #333;
}
.eventsDetail a {
    background: #eb6865;
    border: 1px solid #ff6e6b;
    color: #fff;
    padding:9px 26px;
    font-size:15px;
    margin:18px 6px;
    box-shadow: 0px 1px 2px #b4b4b4e0;
    transition: all .3s ease;
    border-radius: 50px;
    display: inline-block;
    cursor: pointer;
  }
.eventsDetail a:hover{
    opacity: 0.8;
    color: #fff;
}
.eventdetail {
    background-image: url("../images/rea_bg.webp");
	padding:6px 6px 22px 6px;
	background-repeat: no-repeat;
	background-size: cover;
    margin:6px 0;
  }
.eventBg {
    padding: 12px 12px;
    background: #fff;
    margin: 12px 0px;
    border: 1px solid #c8c8c8;
    border-radius:4px;
  }
  .eventdetail h2 {
    font-size: 24px;
    margin: 6px 0px 10px 0px;
    border-bottom: 2px dotted #b18a8a;
    padding: 0px 0 6px 0;
  }
/*Reasons follow us*/
/*news events*/
.news-events {
    text-align: center;
}
.news-bg {
    margin: 12px auto;
}
.news_contant {
    margin: 5px 6px;
    font-size:15px;
    text-align: center;
    color: #000;
}
/*news events*/
/*Awards & Recognitions*/
.awards {
	margin:22px 0;
	text-align: center;
}
.award-detail {
    margin:8px 6px;
    text-align: center;
  }
  .award-detail p {
    font-size: 16px;
    font-weight: bold;
    margin: 3px 0 10px 0;
  }
/*Awards & Recognitions*/
/*Testimoials*/
.testimonials {
    /* background-image: url(../images/bg.jpg); */	
    text-align: center;
}
.testi-hedi {
    background: #ed3237;
    color: #fff;
    font-weight: 500;
    margin: 0;
    font-size: 25px;
    padding: 7px 3px;
    text-shadow: 1px 2px 2px #333;
}
.testimonials img {
	max-width: 100%;
	margin:0 auto;
}
.testi-rel{
    position: relative;
}
.posi-bottom-left img{
    position: absolute;
    top: 0;
    left:3%;
    height: 100%;
}
.posi-bottom-right img{
    position: absolute;
    top: 0;
    right:4%;
    height: 100%;
}
.testi-img {
    margin:15px auto;
}
.testimonials p {
    font-size:17px;
    text-align: justify;
    margin:12px auto;
    line-height:27px;
    font-weight: 500;
    color: #000;
    width:92%;
  }
.tesi-titel {
	font-size: 20px;
	font-weight: 600;
    margin:25px 0;
}
.position_chat {
	position: absolute;
	right: 0;
	bottom: 5%;
}
/*Testimoials*/
/*footer*/
footer {
	background: #ed3237;
	padding: 28px 0 0 0;
	color: #fff;
}
.foot_section {
	width:88%;
	margin: 0 auto;
}
.foot_img_section {
    width:88%;
    margin:5px auto;
}
.foot_section h2 {
    text-align: center;
    font-size:28px;
    padding:6px 0;
    margin: 5px 0;
}
.foot_section h3 {
	font-size: 40px;
}
.foot-nav {
    text-align: right;
    margin: 6px 85px 0 0;
}
.foot-nav ul {
	list-style: none;
}
.foot-nav li {
    display: inline-block;
	padding: 0 22px;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
}
.foot-nav li a {
	color: #fef7f7;
	text-decoration: none;
}
.google-play {
  margin: 8px 12px 8px 55px;
}
.foot_img {
    margin: 8px 0;
  }
  .foot_img_pay {
    margin: 8px 0 6px 0;
  }
  .foot-img-center img {
    max-width:40%;
  }
  .foot_img_pay img {
    max-width: 100%;
  }
.footer_links {
	margin: 14px 0;
}
.footer_links h3 {
    font-size: 21px;
    color: #fff;
    font-weight: 500;
    margin: 10px 0;
    border-bottom: 1px solid #ffffff71;
    padding: 3px 0 8px 0;
    text-transform: uppercase;
  }
.footer_links ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.footer_links li {
    font-size: 16px;
    text-align: left;
    color: #fdf2f1;
    line-height: 32x;
    transition: all .3s ease;
    padding: 0 0px;
}
.footer_links li:hover {
	color: #fff;
	font-weight: 600px;
}
.follow h4 {
	font-size:19px;
	color: #fcefee;
	margin: 4px 0 6px 0;
}
.payment-m h4 {
    font-size: 19px;
    color: #fcefee;
    margin: 4px 0 6px 0;
  }
.team-facebook {
    display: inline-block;
    margin:3px 2px;
  }
.item-social a {
    border: 1px solid #e3e3e3b8;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    margin: 0px 6px 2px 0;
    text-align: center;
    transition: all .3s ease;
    width: 37px;
    padding: 5px 0px;
  }
.item-social a:hover {
	border:1px solid #fff;
	color: #eb6865;
	transition: all .3s ease;
}
.link-address {
    margin: 15px 0;
    font-size: 16px;
  }
/* .link-address img {
    max-width: 55%;
  } */
.link-address a {
	color: #fff;
	font-size: 24px;
	padding: 2px 4px;
	display: inline-block;
}
.address {
    margin: 14px 0 0 -12px;
    font-size: 16px;
  }
.add-input input {
    padding: 10px 12px;
    outline: none;
    font-size: 16px;
    margin: 6px 0;
    color: #fd2823;
    width:100%;
    background: #fff;
    border: none;
  }
.btn-sub {
    background: #fff;
    border: 1px solid #ffffff;
    outline: none;
    padding:7px 24px;
    font-size: 19px;
    color: #ed3237;
    margin: 8px 0 14px 0;
    border-radius: 1px;
    transition: all .5s ease;
}
.btn-sub:hover {
	background:#ed3237;
	color: #fff;
}
.foot-buttom {
	text-align: center;
	border-top:2px solid #fff;
}
.foot-buttom p {
    padding:8px 0;
    font-size: 16px;
    margin: 0;
  }
.footer_links a {
    text-decoration: none;
    font-size: 16px;
    color: #ffffff;
    line-height: 34px;
    padding: 0 0px;
    display: inline-block;
    cursor: pointer;
  }
  .footer_links a:hover {
      color: #fff;
  }
/*footer*/
/* about  */
.about-bg{
	background: #f3f3f3;
	text-align: center;
	margin:3px 0 0 0;
}
.about-bg img{
	max-width: 100%;
}
.about {
    padding: 12px 0;
    text-align: center;
    margin: 4px 0 10px 0;
    background: #fff;
}
.about p {
    font-size: 16px;
    line-height:30px;
    margin:3px 0px;
    color: #000;
    font-weight: 500;
    text-align:justify;
}
.about1 {
    background: #f3f3f3;
    padding:24px 0;
	text-align: center;
    line-height:28px;
}
.abo-hedi {
    font-size:24px;
    padding: 3px 0 8px 0;
    border-bottom: 2px solid #5e5e5eeb;
    display: inline-block;
    font-weight: 500;
    margin: 6px 0 12px 0;
}
.abo-text {
    font-size: 16px;
    line-height: 32px;
    margin: 6px 3px;
    color: #000;
    font-weight: 500;
    text-align:justify;
}
.about-follow {
    display: flex;
    align-items: center;
    justify-content: center;
}
.abo-follow a {
    margin: 6px 8px 22px 8px;
    color: #5d5d5d;
    font-size: 16px;
    display: inline-block;
}
.abo-dir {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin: 8px 0;
    text-shadow: 2px 4px 3px #fff;
    line-height: 24px;
}
.abo-foot {
    max-width: 780px;
    margin:15px auto;
}
.abo-foot-bg {
    background: #f03c41;
    color: #fff;
    font-size: 22px;
    margin: 8px 4px;
    padding: 22px 0;
    transition: .3s ease-in-out;
    border-radius: 6px;
}
.abo-foot-bg img {
    margin:12px 0;
}
.abo-foot-bg:hover {
    -webkit-transform: scale(1.1);
	transform: scale(1.1);
}
.abo-foot-bg h4 {
    font-size: 22px;
    color: #fff;
    margin:10px 0 8px 0;
}
.abo-foot-bg h5{
	font-size:25px;
    color: #fff;
    font-weight: 600;
}
/* about */
/* books  */
.books-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:48px 0;
    background: #eb6865;
    margin:12px 0 12px 0;
}
.books-bg h1 {
    font-size:32px;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
}
.book-img-btn img {
    max-width:100%;
}
.book-img-btn {
    text-align: center;
    margin: 22px 0 12px 0;
}
.book_cbse button {
    border: 1px solid #5e5e5e;
    margin: 12px auto;
    border-radius: 1px;
    display: inline-block;
    padding: 10px 0px;
    text-decoration: none;
    color: #ffffff;
    font-size: 15px;
    text-align: center;
    background:#585858;
    width:175px;
    transition: all .5s ease;
}
.book_cbse button:hover {
    border: 1px solid #f14240;
    background: #ffffff;
    color: #000;
}
.abo-img {
    width:85%;
    padding: 75px 0;
    text-align: center;
}
/*-----box model---- */
.student_popup{
    position: fixed;
    background: rgba(0, 0, 0, 0.44);
    display: flex;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}
.innperPopup{
    width: 650px;
    margin:5px 8px;
    background: #fff;
    border-radius: 5px;
    text-align: center;
}
.modal-header {
    background: #ed3237 !important; 
    padding: 6px 16px !important;
    border-radius: 0!important;
  }
.modal-title {
    color: #fff;
    font-size:20px;
}
.innperPopup-content{
    border: 1px solid #eb6865;
    box-shadow: 0px 15px 10px -10px #111; 
    background:#fff;
}
.close {
    font-size: 20px;
    font-weight: 600;
    line-height: 2;
    color: #fff;
    background: none;
    border: none;
  }
.cos-model {
    transition: all .5s ease;
}
.cos-model:hover {
	-ms-transform: scale(1.1); /* IE 9 */
	-webkit-transform: scale(1.1); /* Safari 3-8 */
	transform: scale(1.1);
}
.index-popup-btn a {
    border: 1px solid #ed3237;
    cursor: pointer;
    margin: 10px auto;
    border-radius: 2px;
    box-shadow: 0px 1px 2px #b4b4b4e0;
    transition: all .5s ease;
    display: inline-block;
    padding: 9px 2px;
    text-decoration: none;
    color: #000;
    font-size: 14px;
    text-align: center;
    width: 90%;
}
.index-popup-btn a:hover {
    background: #ed3237;
	color: #fff;
	border: 1px solid #ed3237;
}
.ActiveButton{
    background: #0000;
}
.borderBottom{
    width: 100%;
    height: 2px;
    background: #999;
}
/*-----box model---- */
/* Loader css  */
.loaderbg {
    align-items: center;   
    display: flex;
    /* height: 100%; */
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    /* width: 100%; */
    z-index: 800;
    /* overflow: hidden; */
  }
.spinner-loading {
    border:8px solid #ffffff;
    border-top: 8px solid #ed3237;
    /* border-right-color: transparent; */
    border-radius: 50%;
   
  
    animation: spin 1s linear infinite;
}
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
   
    100% {
    transform: rotate(360deg);
  
    }
  }

 /* Loader css  */

 /* Search popup */
 .displyflex {
    display: flex;
 }  
.searchpopup-bg {
    position: fixed;
    background: rgba(0, 0, 0, 0.36);
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    justify-content: center;
    align-items: center;
}
.searchpopup {
    position: relative;
    width:80%;
    margin: 4px auto;
  }
.searchpopup input[type="text"] {
  padding: 10px 19px;
  font-size: 16px;
  border: none;
  width:93%;
  background: white;
  outline: none;
  transition: all 0.2s;
  border: 1px solid #8c8c8c;

}
.book-list {
    position: absolute;
    max-height: 350px;
    overflow: scroll;
    overflow-x: scroll;
    overflow-x: hidden;
    background: #fefefe;
    padding:0px 6px;
    z-index: 9999;
    width:100%;
    /* border: 1px solid #8c8c8c;  */
    border-left: 1px solid #8c8c8c;
    margin: 0 0 0 0;
    /* border-bottom: 1px solid #ccc; */
}
/* .book-list {
    max-height: 200px;
    overflow: scroll;
    overflow-x: scroll;
    overflow-x: scroll;
    overflow-x: hidden;
    background: #fefefe;
    border: 1px solid #c4c4c4;
    padding: 0px 12px;
    position: absolute;
    z-index: 9999999999;
    top: 47px;
    width: 88%;
    left: 2%;
    width: 86%;
    left: 2%;
    display: none;
  } */
.book-list img {
    max-width: 100%;
    margin:2px 0;
  }
.booklist-desi {
    font-size: 16px;
    margin:6px 0;
    color: #000;
  }
.searchpopup input[type=text]:hover {
	background: #fffffffa;
}
.searchpopup button {
    padding: 11px 18px;
    background: #000000bf;
    font-size: 16px;
    border: none;
    color: #fff;
  }
/* Search popup */
/* Product Accordion */
.accordion-button {
    color: #1b1b1b !important;
    background: #fff !important;
    padding:12px 12px !important;
}
.accordion-button:focus{
    box-shadow:none!important;
}
.accordion-body a {
    text-decoration: none !important;
}
.accordion-button::after {
    width: 14px !important;
    height: 14px !important;
    background-size: cover !important;
}
.accordion-body {
    font-size: 15px;
    padding:8px 12px !important;
    border-top: 1px solid #cecece !important;
}
.accordion-button.collapsed::after {
    color:#fff !important;
}
.accordion-item {
    background-color: #fff;
    border: none !important;
}
.menuButton.accordion-item a {
    text-decoration: none;
} 
.accordion-item:not(:first-of-type) {
    margin: 0px 0;
}
.accordion-button:not(.collapsed) {
    box-shadow:none !important;
}
.bor {
    border: 1px solid #cecece;
    margin: 6px;
  } 
 /* Product Accordion */
/* Chat box */
.open-button {
    position: fixed;
    bottom: 10px;
    right: 6px;
    padding: 6px 10px;
    font-size: 16px;
    color: white;
    background: #4ecb5b;
    box-shadow: 1px 1px 3px #424141;
    border-radius:5px;
    text-shadow: -1px 0px 1px #000000b8;
  }
.smilefa {
    font-size: 24px;
    margin: 2px 3px;
    color: #fff;
}
.chat-popup {
    position: fixed;
    bottom: 2px;
    right: 0px;
    border: 3px solid #0000005e;
    z-index: 9999;
    border-radius: 15px;
    padding: 0px 2px;
    background: #ffff;
    overflow: hidden;
    animation: bottomUp;
    animation-duration: 0.7s;
  }
@keyframes bottomUp {
    0% {transform: translateY(100%)}
    100% {transform: translateY(0%)}
}
.chat-container {
    width:350px;
    padding: 2px 10px;
    background-color: white;
    color: #616161;
}
.chat-container textarea {
    width: 100%;
    padding:8px;
    margin: 6px 0 0px 0;
    border: none;
    background: #eaeaea;
    resize: none;
    font-size: 14px;
  }
.chat-container textarea:focus {
    background-color: #ddd;
    outline: none;
  }
.chat-container .btn {
    background: #EB6865;
    color: white;
    padding:7px 2px;
    border: none;
    cursor: pointer;
    width:100%;
    margin:4px 0 4px 0;
}
.chat-container .btn:hover {
    color: white;
    outline: none;
    background: #202020;
}
.chat-container .btn:focus {
    box-shadow: none;
  }
  .chat-hedi {
    font-size: 24px;
    color: #000000;
    cursor: pointer;
  }
.chatclosefa {
    color: #eb6865;
}
  .chat-box {
    border: 1px solid #cecbcb;
    padding: 0px 6px;
    margin: 2px 0;
    height: 320px;
    overflow-x: hidden;
    font-size: 15px;
    background: #fff;
    /* background-image: url("../images/chat-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover; */
  }
.chat-box h6 {
    color: #000;
    font-size: 18px;
    margin:8px 0;
}
.chat-section {
    margin: 2px 0;
    /* border: 1px solid #e3e3e3; */
    padding: 3px 6px;
    display: flow-root;
}
.userchat {
    float: right;
    /* width: 53%; */
    background: #d9fdd3;
    font-size: 15px;
    text-align: right;
    padding: 6px 15px;
    margin: 4px 0;
    border: 1px solid #ababab;
    color: #000;
    border-radius: 6px 0 6px 6px;
  }
.mechat {
    float:left;
    width: 50%;
    background: #f8f8f8;
    font-size:13px;
    text-align:left;
    padding:2px 6px;
    border:1px solid #ebebeb;
}
.chatq {
    border: 1px solid #9d9d9d87;
    padding: 5px 6px;
    margin: 10px 41px 10px 0;
    font-size: 15px;
    color: #000;
    background: #fff;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 4px;
}
.chatq1 {
    background: #d9f0ff;
    border: 1px solid #c8c8c8;
    padding: 6px 6px;
    font-size: 15px;
    color: #000;
    border-radius: 4px;
    margin: 6px 41px 6px 0;
}
.queMain {
    font-size: 15px;
    border: 1px solid #d2d2d2;
    padding:6px 6px;
    margin: 10px 42px 10px 0;
    color: #000000;
    background: #ffffff;
    border-radius: 4px;
}
.chatdisable {
    border: 1px solid #aeaeae87;
    padding: 6px 6px;
    margin: 10px 42px 10px 0;
    font-size: 14px;
    color: #000;
    background: #f9f5f5 !important;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background: #fff;
    cursor: not-allowed;
    border-radius: 6px;
}
 /* Chat box */
 /* pageNot  */
.pageNot {
    padding: 10% 0;
    text-align: center;
    font-size: 25px;
    background: #f3f3f36b;
  }
.inlinep p{
    display: inline;
}
/* pageNot  */
/* cuetLive */
.cuet{
  position: fixed;
  left:10px;
  bottom:6px;
  border-radius: 50%;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}
.cuet img{
    max-width:95px;
}
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
}   
/* cuetLive */
/* festival page in home */
.serviceBox {
    margin: 6px 0;
    text-align: center;
    padding:10px 6px;
    background: #fff;
    border-radius:12% 0%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    transition: all 0.5s ease 0s;
  }
.serviceBox:hover{
   padding-top:6px;
}
.serviceBox .service-icon{
    width:95px;
    height:95px;
    line-height:95px;
    font-size:35px;
    color: #4e4e4f;
    margin:10px auto;
    position: relative;
}
.serviceBox .service-icon:before,
.serviceBox .service-icon:after{
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -2px;
    transition: all 0.33s ease-out 0s;
}
.serviceBox .service-icon:before{
    border: 2px solid #00a79c;
    top: -4px;
}
.serviceBox .service-icon:after{
    border: 2px solid #ff794a;
    top: 4px;
}
.serviceBox:hover .service-icon:before{
    top: 4px;
}
.serviceBox:hover .service-icon:after{
    top: -4px;
}
.serviceBox .title{
    font-size:18px;
    font-weight: bold;
    color: #4e4e4f;
    margin:22px 0 6px 0;
}
.serviceBox .description{
    font-size: 14px;
    color: #767676;
}
/* festival page in home */


/* search box home  */
.search-box {
    margin:12px 0 22px 0;
    display: flex;
    position: relative;
}
.search-box input {
    padding: 9px 0 13px 18px;
    font-size: 14px;
    border: 1px solid #999;
    float: left;
    width: 88%;
    background: white;
    outline: none;
    margin: 0 0;
    border-radius: 50px 0 0 50px;
    color: #454545;
}
.search-box button {
    float: left;
    padding:9px;
    background: #363636;
    font-size: 16px;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: all .2s ease-out, color .2s ease-out;
    border-radius: 0 50px 50px 0;
    margin:0 0;
}
.searchfavi {
    margin: 0 6px 0 0;
}
/* search box home  */


.slick-prev, .slick-next {
    background: black !important;
    height:30px !important;
    width: 30px !important;
  }
  .slick-list img {
    margin: 0 auto!important;
    max-width:85%!important;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
     color:#000;
     font-size:18px !important;
     text-transform: capitalize !important;
  }
  .css-1gsv261 {
    border: none !important;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    display: flex;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: rgb(255, 255, 255) !important;
    border: 2px solid #eb6865;
    background: #eb6865;
    border-radius: 50px;
  }
  .css-1aquho2-MuiTabs-indicator {
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    -webkit-transition: none !important;
    transition:none !important;
    background:none !important;
  }
.viewReales{
    background: #eb6865;
    border: 1px solid #eb6865;
    color: #fff;
    padding: 8px 0px;
    font-size: 16px;
    margin: 26px 0 8px 0;
    box-shadow: 0px 1px 2px #b4b4b4e0;
    transition: all .3s ease;
    border-radius: 2px;
}

/* home image popup */
.popup-img-background{
    max-width: 100%;
    margin: 0 6px;
}
.Popup-img {
    position: relative;
    animation:bottomUp;
    animation-duration: 0.8s;
    }
@keyframes bottomUp {
    0% {transform: translateY(100%)}
    100% {transform: translateY(0%)}
}
/* home image popup */

.higlight {
    color: #dc3545;
    font-size: 17px;
    display: block;
    margin: 6px 0 0 0;
}











  





  
