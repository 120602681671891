@media (max-width: 1399.98px) {
   .book-section img {
      height:100%;
    }
    .logo-section {
      margin: 12px auto 8px auto;
    }
    .owl-carousel .owl-item img {
      max-width: 75%;
    }
    .owl-theme .owl-controls .owl-buttons div {
      width: 30px;
      height: 30px;
      line-height: 24px;
    }
    .foot_section h2 {
      font-size: 25px;
    }
    .shop-box {
      padding: 6px 0;
      margin: 2px 6px 2px 6px;
   }
   .shop-hedi{
      font-size:15px;
    }
   .shop-hedi1 {
      font-size:15px;
    }   
    .grid figure {
      width: 62%;
    }
    .our-authors figure {
      width: 50%;
    }
    .reasons-bg {
      padding:8px 0;
      width: 90%;
      margin: 2% auto;
    }
    .core h2 {
      font-size: 19px;
    }
    .abo-button a {
      font-size: 15px;
      margin: 12px auto;
      padding: 6px 16px;
    }
    .posi-bottom-left img {
      top: 8px;
      right: 4%;
      height: auto;
    }
    .posi-bottom-right img {
      top: 8px;
      right: 4%;
      height: auto;
    }
 }
 
@media (max-width: 1199.98px) { 
.logo-section {
      margin:8px auto 8px auto;
 }
.student-parents {
   padding:0px 0;
}
.login {
   padding: 6px 12px;
   font-size: 16px;
   margin: 0 3px;
 }
 .right-login {
   text-align: left;
 }
 .cart {
   margin: 4px 3px;
   padding: 5px 10px;
 }
 .together img {
   max-width: 50px;
 }
.student-parents-btn {
   font-size: 16px;
   padding: 9px 2px;
   width: 25%;
   margin: 0 6px;
 }
.btn_center {
   margin: 2px 0;
}
.student-parents-btn1 {
   font-size: 17px;
   padding: 12px 2px;
   margin:24px 6px 0 0;
   width:30%;
   border: 1px solid;
}  
.navbar-nav li { 
   font-size: 15px;
   margin: 0 6px;
}
/* popup index section */
.modal-title {
   font-size: 18px;
 }
 .cos-model:hover {
	-ms-transform:none;
	-webkit-transform:none;
	transform: none;
}
.close{
   font-size: 18px;
}
.modal-body {
   padding: 3px 6px;
   overflow-y: scroll;
 }
 .index-popup-btn a {
   margin:6px auto;
   padding:8px 2px;
   font-size: 14px;
   width:90%;
 }
/* popup index section */
 .owl-theme .owl-controls .owl-buttons div {
   width: 30px;
   height: 30px;
   line-height: 24px;
 }
 .item p {
   margin:3px;
   font-size: 15px;
 } 
.shop-board {
   padding: 12px 0;
}
.shop-img img {
   width: 100%;
   margin: 22px 0px;
}
.shop-box img {
   max-width: 85%;
}
.shop-box1 img {
   max-width: 85%;
}
.shop-hedi {
   margin:10px 0;
   font-size:14px;
}
.shop-hedi1 {
   margin:10px 0;
   font-size:14px;
}
.about-hedi {
   font-size: 30px;
   margin: 12px 0 8px 0;
}
.abo {
   margin: 10px 0;
}
.abo p {
   font-size: 15px;
   line-height: 28px;
   margin: 6px 0;
   text-align: center;
 }
.our-authors {
   padding:8px 0;
}
.associates {
   margin:8px 0;
}
.asso_ciates img {
   margin: 5px 0 12px 0;
}
.meet_taam {
   padding:6px 6px;
}
.grid figure {
   width: 80%;
}
.core h2 {
   font-size: 19px;
   margin: 10px 0;
}
.core h5 {
   font-size: 16px;
   margin: 8px 0;
}
.reasons-bg {
   padding:8px 0;
}
.wrap-rea {
   max-width: 95%;
}
.reasons-us {
   padding: 5px 0;
}
.awards {
   margin:8px 0;
 }
.reasons-bg h4 {
   font-size: 20px;
   margin: 12px 0 12px 0;
}
.reasons-bg h5 {
   font-size: 25px;
}
.news-events {
   padding: 8px 0 8px 0;
}
.news-hedi {
   font-size: 25px;
   margin: 8px 0 22px 0;
 }
.news-bg {
   margin: 8px auto;
}
.news_contant {
   margin: 6px 6px;
   font-size: 16px;
}
.item-radius img {
   max-width:90% !important;
 }
.item-radius {
   width:160px;
   height:160px;
   padding:22px 12px;
   margin: 14px auto;
}
.testimonials p {
   font-size:16px;
   margin:6px auto !important;
   width: 90%;
 }
 .posi-bottom-left img {
   top: 6%;
   height: auto;
 }
 .posi-bottom-right img {
   top: 6%;
   height: auto;
 }
 .testimonials img {
   max-width: 90%;
 }
 .tesi-titel {
   margin: 6px 0 5px 0;
 }
.foot_section {
   width: 95%;
 }
 .bioGraphy-main {
   width: 98% !important;
 }
.foot_img_section {
   width: 95%;
 }
.foot_section h2 {
   font-size:25px;
   margin: 6px 0 0 0;
   text-align: center;
}
.foot-nav {
   margin: 6px 0 0 0;
   text-align: center;
}
.foot-nav li {
   padding: 0 12px;
}
.footer_links h3 {
   font-size: 20px;
   margin:4px 0;
}
 /* search box & list */
 .book-list {
   max-height: 200px;
}
/* search box & list */
.book_cbse button {
   font-size: 14px;
 }
 .rel-hedi {
   font-size: 23px;
   padding: 6px 3px;
   margin: 0 0 12px 0;
 }
 .testi-hedi {
   font-size: 23px;
   padding: 6px 3px;
 }
 .main_slider {
   padding:5px 2px;
   margin:5px auto;
   width: 99%;
 }
 .main_slider_1{
   padding:5px 2px;
   margin:5px auto;
   width: 99%;
 }

}

@media (max-width: 991.98px) { 
.student-parents {
   padding:0px 0;
}
.student-parents-btn {
   font-size: 13px;
   padding: 8px 1px;
   margin: 20px 2px;
   width: 28%;
 }
.student-parents-btn1 {
   font-size:17px;
   padding: 6px 2px;
   margin:24px 6px 0 0;
   width:30%;
   border: 1px solid;
}  
.btn_center {
   top: 12%;
   width: 58%;
   margin: 0;
 }
.new-release {
   margin: 5px auto;
} 
/* popup index section */
.modal-title {
   font-size: 18px;
 }
 .cos-model:hover {
	-ms-transform:none;
	-webkit-transform:none;
	transform: none;
}
.close{
   font-size: 18px;
}
.modal-body {
   padding: 3px 6px;
 }
.index-popup-btn a {
   margin:6px auto;
   padding:8px 2px;
   font-size: 14px;
   width:90%;
}
/* popup index section */ 
 .item p {
   margin: 6px 9px;
   font-size: 15px;
 } 
.navbar-light .navbar-toggler {
   margin: 8px auto;
}
.navbar-nav > li {
   background: #fff;
   color: #fffdfd;
   border: 1px solid #aeaeae;
   padding: 4px 12px;
   width:95%;
 }
.carousel-indicators [data-bs-target] {
   display: none;
}
.navigation {
   display: block;
   margin: 4px 0 !important;
}
.shop-board {
   padding: 6px 0 22px 0;
}
.shop-img img {
   width: 100%;
   margin: 22px 0px;
}
.shop-box img {
   max-width: 85%;
}
.shop-hedi {
   margin: 6px 0;
   font-size: 16px;
}
.about-hedi {
   font-size:28px;
   margin: 12px 0 6px 0;
 }
.abo {
   margin:8px 0;
}
.abo p {
   font-size: 15px;
   line-height: 26px;
   margin: 0 0 4px 0;
   text-align: justify;
 }
 .author p {
   font-size: 14px;
   text-align: justify;
 }
.abo-button a {
   margin:8px auto;
   padding:7px 18px;
   font-size: 15px;
 }
 .associates_btn a {
   margin:8px auto;
   padding:7px 18px;
   font-size:15px;
 }
 .asso_ciates img {
   max-width: 100% !important;
 }
.our-authors {
   padding: 8px 0;
}
.associates {
   margin: 8px 0;
}
.asso_ciates img {
   margin:6px 0;
}
.associates_btn {
   margin: 5px auto;
}
.meet_taam {
   padding:6px 0px;
}
.grid figure {
   width: 70%;
 }
 .core h2 {
   font-size: 16px;
 } 
.core h5 {
   font-size: 16px;
   margin: 8px 0;
}
.owl-theme .owl-controls .owl-buttons div {
   width: 30px;
   height: 30px;
   line-height: 24px;
 }
 .owl-next::before {
   font-size: 18px;
 }
.reasons-hedi {
   font-size: 25px;
   margin: 6px auto 18px auto;
   padding: 6px 0 4px 0;
 }
.news-bg {
   width: 100%;
   margin:6px auto;
 }
.wrap-rea {
   max-width: 95%;
}
.awards {
   margin: 10px 0;
 }
.reasons-us {
   padding:2px 0;
}
.reasons-bg h4 {
   font-size:16px;
   margin: 12px 0 12px 0;
}
.reasons-bg h5 {
   font-size:16px;
}
.news-events {
   padding: 8px 0 8px 0;
}
.news-hedi {
   font-size: 25px;
   margin: 8px 0 22px 0;
   padding: 3px 0 6px 0;
 }
.news-bg {
   margin: 10px auto;
   padding: 14px 50px 6px 50px;
}
.item iframe {
   width:94%;
   padding: 0 10px;
 }
.news_contant {
   margin: 5px;
   font-size: 14px;
}
.owl-carousel .owl-item img {
   max-width: 75%;
 }
 .item-radius img {
   max-width:90% !important;
 }
.item-radius {
   width:150px;
   height:150px;
   padding:22px 12px;
   margin: 14px auto;
}
.testi-img {
   margin:6px auto 6px auto;
}
.testimonials p {
   font-size:15px;
   margin: 8px 32px;
   line-height:28px;
   text-align: justify;
 }
.foot_section {
   width: 95%;
}
 .foot_section h2 {
   font-size: 25px;
   margin: 6px 0 0 0;
 }
.foot-nav {
   margin: 6px 0 0 0;
   text-align: center;
}
.foot-nav li {
   padding: 0 12px;
}
.link-address {
   margin: 11px -9px;
   font-size: 16px;
 }
.footer_links h3 {
   font-size: 20px;
   margin:4px 0;
}
.foot_img_pay img {
   max-width: 100%;
}

/* search box & list */
.searchpopup {
   width:95%;
 }
 .searchpopup input[type="text"] {
   width: 85%;
   padding: 12px 12px;
   font-size: 15px;
 }
 .searchpopup button {
   width: 15%;
   padding: 13px;
   font-size: 13px;
 }
 .book-list {
   max-height: 200px;
   border:1px solid #ccc;
}
.booklist-desi {
   font-size:14px;
   margin: 3px 0;
   color: #000;
 }
 .cart {
   margin: 5px 3px;
   padding: 5px 12px;
 }
/* search box & list */

 .book_cbse button {
   font-size: 12px;
   width: 100%;
 }
.logo-section {
   margin: 10px auto 6px auto;
 }
 .login {
   padding: 6px 12px;
   font-size: 16px;
   margin: 0 6px;
 }
 .right-login {
   text-align: left;
}
.together img {
   display: none;
 }
 .rel-hedi {
   margin:3px 0;
   font-size: 22px;
   padding:5px 0;
 }
 .rel-hedi-1 {
   margin: 0 0 15px 0;
   padding:5px 0;
   font-size: 22px;
 }
 .testi-hedi {
   margin:3px 0 0 0;
   font-size: 22px;
   padding: 6px 0;
 }
 .main_slider {
   padding:5px 2px;
   margin:5px auto;
   width: 99%;
 }
 .main_slider_1{
   padding:5px 2px;
   margin:5px auto;
   width: 99%;
 }
 .posi-bottom-left img {
   height: auto;
 }
 .posi-bottom-right img {
   height: auto;
 }
 .testimonials img {
   padding: 0;
 }
 .testimonials p {
   font-size:15px;
   margin: 2px auto;
   line-height:22px;
   text-align: justify;
   width: 84%;
 }
 .our-authors figure {
   width: 55%;
 }
}

@media (max-width: 767.98px) { 
.top-bar p {
   font-size: 14px;
}
.logo {
   text-align: center;
}
.logo img {
   margin: 3px 0 8px 0;
}
.text-end {
   text-align: center !important;
 }
.news-bg {
   width: 98%;
}
.login {
   padding: 5px 9px;
   font-size: 15px;
 }
 .chatsho {
   font-size: 19px;
 }
.logo img {
   margin: 4px 0 8px 0;
}
.testi-hedi {
   margin: 3px 0;
   font-size: 22px;
   padding: 4px 0;
 }
 .posi-bottom-left img {
   height: auto;
 }
 .posi-bottom-right img {
   height: auto;
 }
.right-login {
   text-align: left;
}
.together img {
   width: 50px;
 }
.student-parents-btn {
   font-size: 14px;
   padding: 8px 2px;
   margin: 6px 0;
   width:55%;
}
/* popup index section */
.modal-title {
   font-size: 18px;
 }
 .cos-model:hover {
	-ms-transform:none;
	-webkit-transform:none;
	transform: none;
}
.close{
   font-size: 18px;
}
.modal-body {
   padding: 3px 6px;
 }
 .body-box {
   overflow-y: scroll;
   height: 260px;
 }
 .index-popup-btn a {
   margin:6px auto;
   padding:8px 2px;
   font-size: 14px;
   width:75%;
 }
/* popup index section */
.rel-hedi {
   margin: 5px 0 24px 0;
   padding:4px 0 8px 0;
   font-size: 25px;
 }
.owl-carousel .owl-item img {
   max-width:90%;
}
.owl-carousel .owl-nav button.owl-prev {
   top: 33%;
   left: 1%;
   font-size: 22px;
   line-height: 32px;
}
.owl-carousel .owl-nav button.owl-next {
   top: 33%;
   right: 1%;
   font-size: 22px;
   line-height: 32px;
}
.item p {
   font-size: 15px;
   width: 80%;
 }
.navbar-light .navbar-toggler {
   margin: 8px auto;
}
.navbar-nav > li {
   background: #fff;
   color: #fffdfd;
   border: 1px solid #aeaeae;
   padding: 4px 12px;
   width:95%;
 }
.carousel-indicators [data-bs-target] {
   display: none;
}
.navigation {
   display: block;
}
.abo-button {
   margin: 12px auto;
}
.new-release {
   margin: 6px auto;
}
.shop-img img {
   margin: 3px 0 12px 0;
   width:55%;
   display: none;
}
.btn_center {
   margin: 6px 0;
   position: inherit;
   width: 100%;
   text-align: center;
 }
.our-authors figure {
  width:72%;
}
.reasons-bg {
   padding:5px 0;
   width: 100%;
 }
 .reasons-us {
   padding: 10px 0;
 }
.abo {
   margin:6px 0;
}
.abo img {
   max-width: 18%;
   display: none;
}
.about-hedi {
   margin: 3px 0 8px 0;
   font-size: 28px;
}
.abo p {
   font-size: 16px;
   line-height: 32px;
   margin: 0 0 4px 0;
   text-align: center;
}
.abo-button a {
   margin: 6px auto;
   padding: 7px 18px;
   font-size:15px;
 }
.our-authors {
   padding: 12px 0;
}
.rel-hedi {
   margin:8px 0;
   font-size: 22px;
   padding: 4px 0;
 }
 .rel-hedi-1 {
   margin: 0 0 15px 0;
   padding: 4px 0;
   font-size: 22px;
 }
 .main_slider {
   padding:3px 2px;
   margin:3px auto;
   width: 99%;
 }
 .main_slider_1{
   padding:3px 2px;
   margin:3px auto;
   width: 99%;
 } 
.title {
   font-size: 18px;
   color: #e8504d;
   margin: 4px 0 8px 0;
}
.author p {
   font-size: 16px;
   text-align:center;
}
.associates {
   margin:22px 0;
}
.asso_ciates img {
   margin: 6px 0 0px 0;
}
.meet_taam {
   padding: 12px 0 12px 0px;
}
.grid figure {
   width:60%;
}
.core h2 {
   font-size: 18px;
   margin:3px 0;
}
.core h5 {
   font-size: 15px;
   margin: 6px 0;
}
.reasons-us {
   padding: 6px 0 6px 0;
}
.wrap-rea {
   max-width: 98%;
}
.reasons-hedi {
   font-size: 25px;
   margin: 3px auto 15px auto;
   padding: 4px 0 4px 0;
 }
.reasons-bg {
   padding:6px 0;
   height: 98%;
}
.reasons-bg img {
   max-width: 52%;
}
.reasons-bg h4 {
   font-size: 16px;
   margin: 12px 0 12px 0;
}
.reasons-bg h5 {
   font-size: 22px;
}
.associates_btn a {
   margin:15px auto;
   padding: 7px 18px;
   font-size:15px;
 }
 .owl-theme .owl-controls .owl-buttons div {
   width: 34px;
   height: 34px;
   line-height: 30px;
 }
 .news-events {
   padding: 4px 0;
 }
.news-hedi {
   font-size: 35px;
   margin: 3px 0 3px 0;
}
.news-bg {
   margin: 14px auto;
   padding: 12px 18px 12px 18px;
}
.news_contant {
   margin: 3px 2px;
   font-size: 17px;
}
.awards {
   margin: 6px 0;
}
.news-hedi {
   font-size: 25px;
   margin: 3px 0 22px 0;
   padding: 3px 0 6px 0;
 }
.awards img {
   max-width: 100%;
   margin: 42px 0;
}
.asso_ciates img {
   max-width: 96% !important;
 }
 .item iframe {
   width: 95%;
   padding: 0 16px;
   height: 300px;
 }
 .item-radius img {
   max-width: 80% !important;
 }
.item-radius {
   width:150px;
   height:150px;
   padding:22px 12px;
   margin: 14px auto;
}
.testimonials img {
   max-width: 95%;
   margin: 0px auto;
   display: none;
}
.testi-hedi {
   font-size: 25px;
   margin: 3px 0 22px 0;
   padding: 3px 0 6px 0;
}
.testi-img {
   margin: 5px auto 5px auto;
}
.tesi-titel {
   font-size: 20px;
   margin: 4px 0;
}
.student-parents {
   padding: 12px 0;
   margin: 0 0 8px 0;
}
footer{
   padding: 12px 0;
}
.foot_section {
   width: 98%;
}
.foot_section h2 {
   font-size: 22px;
   padding: 4px 0;
   margin: 4px 0px 12px 0;
}
.foot-nav ul {
   margin: 0;
   padding: 0;
}
.foot-nav {
   text-align: center;
   margin: 6px 0px 6px 0;
}
.foot-nav li {
   padding: 0 8px;
   font-size: 16px;
}
.foot_section img {
   margin:3px auto;
}
.footer_links h3 {
   font-size: 20px;
   border: none;
   padding: 3px 0 0 6px;
}
.footer_links {
   text-align: left;
   margin: 4px 0 4px 0;
   padding: 0 0 0 16px;
}
.footer_links li {
   text-align: left;
   padding: 0 0 0 12px;
}
.link-address {
   margin: 10px 6px;
   text-align: left;
}
.add-input {
   text-align: center;
}
.btn-submit {
   text-align: center;
}
.add-input input {
   padding: 6px 11px;
   font-size: 18px;
   margin: 6px 0;
   width: 70%;
   display: block;
   margin: 0 auto;
}
/* Chat box */
.smilefa {
  font-size: 14px;
  margin: 0 4px;
}
.chat-popup {
   bottom:32px;
   right: 0px;
 }
 .chat-container {
   width: 242px;
   padding: 6px;
 }
 .chat-box {
   height: 250px;
   margin: 3px 0;
 }
 .chat-container textarea {
   padding: 4px;
   margin: 3px 0 3px 0;
   font-size: 12px;
 }
 .chat-container .btn {
   padding: 3px 2px;
   width: 100%;
   margin: 0 0;
 }
/* Chat box */

/* search box & list */
.searchpopup {
   width:95%;
 }
 .searchpopup input[type="text"] {
   width: 85%;
   padding:9px 12px;
   font-size: 15px;
 }
 .searchpopup button {
   width: 18%;
   padding:9px;
   font-size: 16px;
}
.navigation {
   margin: 0px 0 0 0 !important;
 }
 .book-list {
   max-height: 200px;
}
.booklist-desi {
   font-size: 12px;
   margin: 3px 0;
   color: #000;
 }
/* search box & list */

.foot-img-center img {
   max-width: 100%;
 }
 .foot-img-center {
   margin: 6px  0;
 }
}

@media (max-width: 575.98px) { 
.top-bar p {
      font-size:13px;
      margin: 0 3px;
}
.top-bar{
   padding: 5px 0;
}
.back_width {
   width: 100%;
}
.logo {
   text-align:left;
}
.logo img {
   margin:4px 0 3px 0;
   max-width:80%;
}
.logo-section {
   padding: 0 0;
   margin: 8px 0 0 0;
   text-align: center;
   width: 100%;
   position: relative;
}
.cart {
   margin: 0 0;
   position: absolute;
   top: 2%;
   right: 0;
   padding: 4px 10px;
 }
 .cartcount {
   bottom: 23px;
   left: 19px;
 }
.together img {
   max-width:100%;
   display: inline-block;
 }
.right-login {
   margin: 4px 0;
   text-align: center;
   /* position: relative; */
 }
.login {
   padding: 5px 14px;
   font-size: 16px;
}
.search{
   position: absolute;
   bottom: -68%;
   right: 4%;
   z-index: 99999;
   font-size: 16px;
}
.otp_btn {
   width: 46%;
}
.student-parents-btn {
   font-size: 14px;
   padding: 8px 2px;
   margin: 6px 0;
   width: 98% !important;
}
.student-parents-btn1 {
   font-size: 14px;
   padding: 8px 2px;
   margin: 6px 0;
   width: 95% !important;
   border: 1px solid #ccc;
}	
/* popup index section */
.modal-title {
   font-size: 16px;
 }
 .cos-model:hover {
	-ms-transform:none;
	-webkit-transform:none;
	transform: none;
}
.close{
   font-size: 16px;
}
.modal-body {
   padding: 3px 6px;
 }
 .body-box {
   overflow-y: scroll;
   height: 260px;
 }
 .index-popup-btn a {
   margin:6px auto;
   padding:8px 2px;
   font-size: 12px;
   width: 98%;
 }
/* popup index section */
.owl-prev {
   left: -5%;
 }
 .owl-next {
   right: -5%;
 }
 .owl-carousel .owl-item img {
   max-width: 90%;
 }
.rel-hedi {
   margin: 8px 0;
   padding: 4px 0;
   font-size:22px;
}
.rel-hedi-1 {
   margin: 0 0 15px 0;
   padding: 4px 0;
   font-size:22px;
}
.main_slider {
   padding: 4px 2px;
   margin: 5px auto;
   width: 99%;
 }
 .main_slider_1{
   padding: 4px 2px;
   margin: 5px auto;
   width: 99%;
 }
 .shop-box button {
   margin: 23px 0 0 0px;
 }
.directors-hedi {
   font-size: 22px;
   padding: 4px 0 4px 0;
   margin: 12px 0 22px 0;
 }
 .bottom-border10 {
   width: 85%;
   margin: 8px auto 22px auto;
 }
.student-parents {
   margin: 0 0 10px 0;
 }
 .owl-theme .owl-controls .owl-buttons div {
   width: 30px;
   height: 30px;
   line-height: 24px;
 }
.btn_center {
   margin: 4px 0;
   position: inherit;
   width: 100%;
}
.navigation {
   display: block;
   margin: 6px 0 0 0 !important;
   background: #6868681a;
 }
.navbar-toggler {
   padding: 0px 4px !important;
   font-size:20px!important;
 }
.navbar-light .navbar-toggler {
   margin:6px 0 6px 0;
}
.navbar-nav > li {
   background: #fff;
   color: #fffdfd;
   border: 1px solid #aeaeae;
   padding: 4px 12px;
   width:98%;
 }
 .navbar-nav li { 
   font-size: 16px;
   margin: 0 0px 0 0px;
}

.carousel-indicators [data-bs-target] {
   display: none;
}
.owl-carousel .owl-nav button.owl-prev {
   top: 33%;
   left:0%;
   font-size: 19px;
   line-height: 28px;
}
.owl-carousel .owl-nav button.owl-next {
   top: 33%;
   right:0%;
   font-size:19px;
   line-height:28px;
}
.item p {
   margin: 6px 25px;
   font-size: 14px;
   min-height: auto;
 }
.new-release {
   margin: 6px auto;
}
.together {
   margin: 3px 0 0 12px;
   display: inline-block;
}
.shop-board {
   padding: 6px 0 16px 0;
}
.shop-img img {
   margin: 6px 0;
   max-width: 100%;
   display: none;
}
.shop-box {
   padding: 12px 0;
   min-height: auto;
   margin: 6px 22px;
}
.shop-box1 {
   padding: 12px 0;
   min-height: auto;
   margin: 6px 22px;
}
.abo {
   margin: 6px 2px;
 }
 .abo-button a {
   margin: 6px auto;
   padding: 7px 14px;
   font-size: 14px;
 }
.abo img {
   max-width: 75%;
}
.about-hedi {
   margin: 8px 0 4px 0;
   padding: 4px 0;
   font-size: 28px;
}
.abo p {
   font-size: 14px;
   line-height: 28px;
   margin: 0 0 4px 0;
   text-align: justify;
 }
.our-authors {
   padding:6px 0;
}
.title {
   font-size: 18px;
   color: #e8504d;
   margin: 8px 0;
}
.author p {
   font-size: 15px;
   text-align: center;
   margin: 0;
 }
 .our-authors figure {
   width: 55%;
 }
.associates {
   margin: 8px 0;
}
.asso_ciates img {
   margin: 6px 0 8px 0;
   max-width:95% !important;
 }
.meet_taam {
   padding: 12px 0 12px 0px;
}
.associates_btn a {
   margin: 4px auto;
   padding: 7px 15px;
   font-size: 14px;
 }
 .grid figure {
   width: 90%;
 }
.core h2 {
   font-size: 18px;
   margin:8px 0;
}
.core h5 {
   font-size:14px;
   margin: 8px 0;
}
.grid {
   margin: 3px 0;
 }
.reasons-us {
   padding: 3px 0;
}
.wrap-rea {
   max-width: 95%;
}
.reasons-hedi {
   font-size: 25px;
   margin: 10px auto 10px auto;
 }
 .reasons-bg img {
   max-width:40%;
   margin:3px 0;
 }
.reasons-bg {
   padding: 4px 0;
   min-height: auto;
   margin: 3px 0;
   width: 100%;
}
.reasons-bg:hover {
	-ms-transform: scale(1.0); /* IE 9 */
	-webkit-transform: scale(1.0); /* Safari 3-8 */
	transform: scale(1.0);
}
.reasons-bg h4 {
   font-size: 16px;
   margin:5px 0;
}
.reasons-bg h5 {
   font-size:20px;
   margin: 2px 0;
}
.news-events {
   padding:4px 0;
}
.author {
   margin: 12px 0;
 }
.news-hedi {
   font-size: 35px;
   margin: 3px 0 3px 0;
}
.news-bg {
   margin: 6px auto;
   padding: 3px 5px;
   width: 92%;
 }
 .news_contant {
   margin: 3px 3px;
   font-size: 15px;
 }
.item iframe {
   width:98%;
   height: 260px;
}
.awards {
   margin: 6px 0;
}
.news-hedi {
   font-size: 25px;
   margin: 6px 0 22px 0;
} 
.awards img {
   margin: 6px 0;
}
.item-radius {
   width: 180px;
   height: 180px;
   padding:28px 12px;
 }
 .item-radius img {
   max-width: 80% !important;
 }
.testimonials {
   padding:8px 0;
}
.testimonials img {
  display: none;
  height: auto;
}
.testi-hedi {
   margin: 8px 0;
   padding: 4px 0;
   font-size: 22px;
 }
.testi-img {
   margin: 5px auto 5px auto;
   width: 40px;
}
.testimonials p {
   font-size: 15px;
   margin: 0px 3px;
   line-height: 26px;
   width:98%;
   text-align: justify;
 }
.tesi-titel {
   font-size: 22px;
   margin:6px 0;
}
.position_chat {
   display: none;
}
footer{
   padding: 12px 0;
}
.foot_section {
   width: 98%;
}
.foot_section h2 {
   font-size:20px;
   padding: 4px 2px;
   margin: 6px 0px 10px 0;
}
.foot-nav ul {
   margin: 0;
   padding: 0;
}
.foot-nav {
   text-align: center;
   margin: 6px 0px 6px 0;
}
.foot-nav li {
   padding: 0 8px;
   font-size: 16px;
}
.foot_section img {
   margin: 9px auto;
}
 .follow h4 {
   margin: 6px 0 8px 0;
 }
.footer_links h3 {
   font-size: 20px;
   border-top: 1px solid #a3a3a3;
   padding: 18px 0 0 0;
}
.footer_links li {
   text-align: center;
}
.add-input {
   text-align: center;
}
.btn-submit {
   text-align: center;
}
.footer_links {
   text-align: center;
   margin: 3px 0 3px 0;
   padding: 0 0;
 }
.link-address {
   margin: 11px 48px;
   text-align: center;
}
.foot-buttom p {
   padding: 6px 0;
   font-size: 16px;
   margin: 0;
}
.add-input input {
   padding: 8px 4px;
   font-size: 16px;
   margin: 3px 0;
   width: 85%;
   display: block;
   margin: 0 auto;
}
.btn-sub {
   padding: 6px 26px;
   font-size: 16px;
}
.follow {
   text-align: center;
}
.payment-m h4 {
   text-align: center;
   margin: 12px 0 0 0;
 }
 .item-social a {
   margin: 0px 3px 2px 0;
   width: 34px;
   padding: 3px 0px;
 }
.foot_section {
   width: 100%;
}
.foot_img {
   text-align: center;
}
.google-play {
   margin: 9px 0;
}
.foot_img {
   margin: 6px 0 6px 0;
}
.foot-img-center img {
   max-width: 82%;
 }
.foot-img-center {
   text-align: center;
   margin: 8px auto;
 }
.foot_img_pay {
   margin: 6px 0 6px 0;
   text-align: center;
}
.payment {
   margin: 3px 0px;
}
/* Chat box */
.open-button {
   bottom:1px;
   right: 1px;
   /* width:100px; */
   padding: 6px 5px;
   font-size: 12px;
 }
 .smilefa {
  font-size:18px;
  margin: 0 3px;
}
.chat-popup {
   bottom:0px;
   right: 0px;
 }
 .chat-container {
   width:100%;
   padding: 6px;
 }
 .chat-box {
   height: 170px;
   margin: 3px 0;
 }
 .chat-container textarea {
   padding: 4px;
   margin: 3px 0 3px 0;
   font-size: 12px;
 }
 .chat-container .btn {
   padding: 3px 2px;
   width: 100%;
   margin: 0 0;
 }
/* Chat box */
/* search box & list */
.searchpopup {
   margin: 8px auto 8px auto;
   width: 100%;
 }
.banner {
   margin: 4px 0 0 0;
 }
.navbar-light .navbar-toggler {
   color: rgba(0, 0, 0, 0.55) !important;
   border-color: rgba(0, 0, 0, 0.84) !important;
 }
.searchpopup input[type="text"] {
   width:86%;
   padding:10px 8px;
   font-size: 14px;
}
.searchpopup button {
   width: 14%;
   padding: 12px;
   font-size: 13px;
 }
.book-list {
   max-height: 200px;
}
.booklist-desi {
   font-size:14px;
   margin:2px 0;
   color: #000;
   text-align: left;
 }

/* search box & list */
/* wislist */
.wislist-main {
   margin: 6px 0 6px 0;
   padding: 3px 6px;
 }
.mycart h3 {
   margin: 6px 0 4px 0;
 }
.mycart h4 {
   margin: 3px 0;
 }
.invi-authors::before {
   left: 42%;
 }
/* wislist */
/*cuet */
.cuet {
   left:4px;
   bottom:0px;
 }
.cuet img {
   max-width:40px;
 }
/*cuet  */
}