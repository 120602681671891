.mainImg {
    max-width: 300px;
    position: relative;
    cursor: pointer;
    margin:4px 0 3px 0;
   
    /* display: flex; */
    /* justify-content: center; */
}
.mainImg > div>  img {
    /* width: 100%; */
    max-width:100%;
    border: 1px solid #eb6865;
    transition: opacity 1s
}
.bottomImgBox > img {
    width: 100%;
    
 }
.zoomContainer {
    width: 350px;
    height: 466.6px;
    /* background-color: blueviolet; */
    position: absolute;
    top: -10%;
    left: 105%;
    z-index: 1000;
    border: 1px solid gray;
    opacity: 0;
    transition: opacity 1s;
    visibility: hidden;
}
.lensBox {
    border: 1px solid red;
    width:95px;
    height:95px;
    position: absolute;
    top:0;
    left: 0;
    opacity: 0;
    transition: opacity 1s;
    visibility: hidden;

}
.bottomImgBox {
    display: inline-block;
    width: 50px;
    margin: 6px;
    cursor: pointer;
   
}

.selected {
    border: 1px solid rgb(253, 100, 100);
}

.notSelected {
    opacity: 0.5;
}
.higlight {
    color: #b5530d;
    border:1px solid rgb(40, 40, 40);
}
