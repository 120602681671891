/* cbse  */
.books-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 42px 0;
    background: #fd4247;
    margin: 12px 0 12px 0;
}

.books-bg h3 {
    font-size: 38px;
    color: #fff;
    font-weight: 500;
    /* text-shadow: 1px 1px 2px #3333338c; */
    text-transform: uppercase;
}

.board-from {
    text-align: center;
    margin: 20px 0 25px 0;
}

.board-from select {
    background: #fff;
    border: 1px solid #5c5c5c;
    color: #161616;
    font-size: 1em;
    font-weight: 400;
    line-height: 1em;
    padding: 12px 6px;
    width: 98%;
}

.board-from select:focus {
    outline: none;
}

.book-section {
    position: relative;
    margin: 8px auto 0 auto;
    width: 95%;
    text-align: center;
    box-shadow: rgba(99, 99, 99, 0.54) 0px 1px 4px 0px;
}

.book-section img {
    width: 100%;
    height: 345px;
}

.favi svg {
    position: absolute;
    top: 2%;
    right: 6%;
    background: #5822223d;
    border-radius: 50px;
    width: 40px;
    height: 40px;
}

.rel button {
    background: #ed3237;
    padding: 6px 19px;
    border: 2px solid #ed3237;
    font-size: 14px;
    color: #ffffff;
    margin: 12px 0px;
    transition: all .5s ease;
    border-radius: 0px;
}

.rel button:hover {
    background: none;
    border: 2px solid #ed3237;
    color: #000000;
}

.overlay {
    position: absolute;
    bottom: 0%;
    left: 0;
    right: 0;
    background-color: rgba(82, 82, 82, 0.74);
    overflow: hidden;
    width: 80% !important;
    height: 0 !important;
    transition: .5s ease;
    cursor: pointer;
    margin: 0 auto;
}

.overText {
    position: relative;
}

.overText:hover .overlay {
    padding: 20px 0;
}

.over-text {
    color: rgb(255, 255, 255);
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.book-dis {
    font-size: 15px;
    color: #000;
    width: 95%;
    margin: 0px auto;
    background: #f4f4f4b2;
    min-height: 90px;
    padding: 3px 2px;
    text-align: center;
    border: 1px solid #e9e9e9;
}

.book-price {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    margin: 8px auto 20px auto;
    text-align: center;
    width: 95%;
}

.book-dis a {
    color: #000;
    transition: transform .3s;
}

.book-dis a:hover {
    color: rgb(39, 39, 39);
    font-weight: bold;
}

strike {
    color: #f95100;
}

.bookPer {
    color: #6c6c6c;
}

/* cbse  */
/* breaking-walls */
.breadcrumb {
    background: none;
    margin: 8px 0;
    border-bottom: 1px solid #b1b1b1;
    padding: 0 0 8px 0 !important;
}

.breadcrumb-item a {
    color: #0d6efd;
}

.page-item {
    outline: none;
}

.walls-btn>span {
    background: #fff;
    outline: none;
    padding: 9px 2px;
    font-size: 16px;
    color: #151515;
    border: 1px solid #f93f44;
    margin: 15px 8px;
    border-radius: 1px;
    transition: all .3s ease;
    width: 148px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

.walls-btn>span:hover {
    background: rgb(23, 23, 23);
    color: #fff;
}

.walls-btn-selected {
    background-color: #f93f44 !important;
    color: #ffffff !important;
}

.walls img {
    max-width: 100%;
    margin: 4px 0 22px 0;
    border: 1px solid #eb6865;
}

.box-bold {
    font-weight: 600;
    font-size: 18px;
}

.walls p {
    padding: 2px 0px;
    margin: 0;
    font-size: 16px;
    text-align: justify;
    color: #333;
    line-height: 28px;
}

.walls h1 {
    font-size: 20px;
    text-align: left;
    margin: 0 0 10px 0;
}

.walls .price {
    font-size: 18px;
    color: #606060;
    margin: 6px 0 12px 0;
}

.cart-width a {
    color: #fff;
    text-decoration: none;
    display: block;
}

.cart-width {
    border: none;
    font-size: 17px;
    width: 75%;
    padding: 12px 0;
    background: #f93f44;
    color: #fff;
    margin: 5px 12px 20px 12px;
    border-radius: 50px;
    display: inline-block;
}

.main-cart-section {
    display: grid;
    grid-template-columns: 3fr 3fr 1fr;
}

.cart-width:hover {
    opacity: 0.8;
}

.buyNow {
    border: none;
    font-size: 17px;
    width: 75%;
    padding: 12px 0;
    background: #444444;
    color: #fff;
    margin: 5px 12px 20px 12px;
    border-radius: 50px;
    display: inline-block;
}

.buyNow:hover {
    opacity: 0.8;
}

.heart {
    border: 1px solid #f93f44;
    padding: 10px 0;
    margin: 3px 2px;
    float: right;
    text-align: center;
    width: 68px;
    height: 50px;
}

.card-header {
    background-color: rgb(255 255 255);
    padding: 8px 12px;
}

.panel-heading {
    padding: 0px 2px;
    position: relative;
    color: #131313;
    font-size: 18px;
    cursor: pointer;
}

.panel-body p {
    padding: 8px 16px;
    margin: 0;
    font-size: 16px;
    text-align: justify;
    color: #333;
    line-height: 28px;
}

.favi-ac {
    position: absolute;
    right: 0%;
}

.fa-ac {
    font-size: 14px;
    color: #ed5855;
}

.bukBox {
    border: 1px #CCC solid;
    border-radius: 5px;
    padding: 12px;
    text-align: justify;
    background-color: #FFF;
    margin: 12px 0;
}

.tab-heading {
    border: 1px solid #d2d2d2;
    border-radius: 2px 0;
}

.customer_hedi {
    background: #ededed;
    border-bottom: 1px solid #d2d2d2;
    padding: 0 6px;
    margin: 0 0 8px 0;
}

.tab-heading h2 {
    padding: 3px 6px;
    font-size: 20px;
    margin:8px 0;
}
.no-reviews {
    padding: 0 12px;
  }
.rating_tab {
    margin: 10px 12px;
}

.progress_tab {
    font-size: 18px;
    margin: 6px 0;
}

.related_books {
    margin: 10px 0 22px 0;
    text-align: center;
}

.related_books h3 {
    margin: 8px 0 32px 0;
    border-bottom: 2px dotted #444;
    padding: 0px 0 10px 0;
    text-align: center;
    font-size: 26px;
}

.linethrough {
    text-decoration: line-through;
    margin: 0 12px;
}

/* breaking-walls */
/* Right cart popup */
.cart_popup {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1050;
    width: 445px;
    height: 100%;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    animation: slideLeft;
    animation-duration: 0.7s;
}

@keyframes slideLeft {
    0% {
        transform: translateX(100%)
    }

    100% {
        transform: translateX(0%)
    }
}

.cart-header {
    background: #050505 !important;
    padding: 4px 10px !important;
}

.rightcart {
    margin: 3px 0;
}

.rightcart img {
    max-width: 100%;
}

.right-price {
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0;
}

.rightcart1 {
    margin: 3px 0;
}

.rightcart1 h6 {
    margin: 3px 0;
    font-size: 15px;
    text-align: justify;
}

.rempty-cart {
    margin: 6px 0px;
    font-size: 18px;
    color: #f93f44;
    cursor: pointer;
    text-align: center;
}

.right-foot {
    position: fixed;
    bottom: 1%;
}

.view-cart {
    border: none;
    font-size: 16px;
    width: 412px;
    padding: 12px 0;
    background: #f93f44;
    color: #fff;
    margin: 2px 0 4px 0;
}

.cart_total {
    font-size: 17px;
    margin: 0 8px;
    font-weight: bold;
}

.rupes_cart {
    font-size: 17px;
    color: #000;
}

/* Right cart popup */
/* mycart  */
.cart-bg {
    background: #e6e6e6;
    padding: 2px 0;
    margin: 6px 0 0 0;
}

.mycart {
    background: #fff;
    padding: 6px 32px;
}

.cart-bg img {
    max-width: 100%;
}

.mycart h1 {
    font-size: 22px;
    border-bottom: 1px solid #8282829e;
    padding: 4px 0 8px 0;
    margin: 0 0 6px 0;
}

.mycart h2 {
    font-size: 22px;
    border-bottom: 1px solid #8282829e;
    padding: 4px 0 8px 0;
    margin: 0 0 6px 0;
}

.mycart h3 {
    font-size: 16px;
    margin: 8px 0;
}

.mycart h4 {
    font-size: 16px;
    color: #ff4a46;
    margin: 9px 0 0 0;
}

.right-number {
    border: 1px solid #969696;
    display: flex;
    width: 100px;
    text-align: center;
    margin: 6px 0;
}

.right-number input {
    width: 30px;
    margin: 0 auto;
    border: none;
    font-size: 16px;
    color: #000;
    text-align: center;
    background: #fff;
}

.right-number input:focus {
    outline: none;
}

.minus-right {
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    font-size: 20px;
    padding: 0 4px;
}

.plus-right {
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    font-size: 20px;
    padding: 0 4px;
}

.multiple {
    display: flex;
    justify-content: space-between;
    margin: 8px 0 9px 0px;
}

.multiple h4 {
    font-size: 16px;
    color: #262626;
    margin: 1px 0 0 0;
}

.multi_empty {
    color: #f93f44;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
}

.check-out {
    text-align: center;
}

.check-out button {
    border: none;
    font-size: 16px;
    width: 210px;
    padding: 9px 0;
    background: #f93f44;
    color: #fff;
    margin: 18px 0;
}

.check-out button:hover {
    opacity: 0.7;
}

.mycart-collapse {
    margin: 12px 0 4px 0;
    font-size: 18px;
    display: inline-block;
    cursor: pointer;
}

.note-flax {
    display: flex;
    justify-content: space-around;
}

.left-collapse {
    text-align: left;
    margin: 0 22px;
}

.mycart-collapse:hover {
    color: #f93f44;
}

.mycart-input {
    padding: 9px 12px !important;
    border: 1px solid #b9b9b9 !important;
}

.input-group-text {
    padding: 9px 24px !important;
    background: #fff !important;
    font-size: 16px;
    border: 1px solid #b9b9b9;
}

.input-group-text:hover {
    border: 1px solid #f93f44;
    color: #ffffff;
    background: #f93f44 !important;
}

.border-bootom.row {
    border-bottom: 1px solid #b4a3a3ab;
    margin: 4px 0;
}

.img-thumbnail {
    margin: 6px 0 !important;
}

.cart-mhedi {
    font-size: 24px;
    padding: 3px 0;
    border-bottom: 2px solid #5e5e5eeb;
    display: inline-block;
    font-weight: 500;
    margin: 4px 0 4px 0;
}

.order-cal {
    font-size: 16px;
    color: #000;
}

.cartdot {
    font-size: 16px;
    margin: 0 12px;
    color: #000;
}

.discount-border {
    border-bottom: 1px solid #bcbcbc;
    margin: 6px 20px;
}

.garnd-total {
    font-size: 20px;
}

/* mycart */
/* Contact us */
.contact-us {
    margin: 12px 0;
    text-align: center;
}

.contact-us img {
    max-width: 100%;
}

.contact-us h1 {
    font-size: 26px;
    padding: 6px 0;
    border-bottom: 2px solid #5e5e5eeb;
    display: inline-block;
    font-weight: 500;
    margin: 12px 0 12px 0;
}

.contact-us p {
    color: #000;
}

address {
    margin: 24px 0;
}

.add-from {
    margin: 22px 0;
    text-align: left;
    font-size: 16px;
    color: #000;
}

.add-from a {
    text-decoration: underline;
    color: #000;
}

.add-from label {
    font-size: 15px;
    color: #000;
    margin: 12px 0 12px 0;
}

.form-control:focus {
    box-shadow: none;
}

.form-contact {
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #525252de !important;
}

.form-control:focus {
    border-color: #e8504d !important;
    box-shadow: none !important;
}

.contact-btn {
    background: #ed3237;
    border: 1px solid #ed3237;
    color: #fff;
    padding: 8px 42px;
    font-size: 17px;
    margin: 26px 0 8px 0;
    box-shadow: 0px 1px 2px #b4b4b4e0;
    transition: all .3s ease;
}

.contact-btn:hover {
    border: 1px solid #525252de;
    background: none;
    color: #000
}

.contact-social {
    display: flex;
    justify-content: space-between;
    margin: 24px 0 6px 0;
}

.branch-bg {
    background: #f3f3f37a;
    margin: 8px 0 0 0;
    padding: 14px 0;
}

.Off-branch {
    text-align: center;
    margin: 0px 0 14px 0;
    font-size: 24px;
    border-bottom: 2px dotted #111111ab;
    padding: 2px 0;
}

/* Contact us */
/* login form */
.form-wrap {
    background-color: #fff;
    width: 540px;
    margin: 26px auto;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
    display: flow-root;
}

#warp-login {
    margin: 24px 38px;
}

.login-main {
    text-align: center;
    background: #464a4c;
    padding: 10px 0;
    font-size: 20px;
    color: #fff;
}

.form-mar {
    margin: 28px 42px;
}

.login-mar {
    padding: 8px 12px !important;
    border: 1px solid #bdbdbd !important;
    margin: 6px 0 6px 0;
}

.login-mar:focus {
    border: 1px solid #f18583d1 !important;
}

.login-hedi {
    margin: 20px 0 2px 0;
    font-size: 16px;
    text-shadow: 0 0 #6e6d6d;
}

#login-form {
    padding: 0 28px;
}

.login-button {
    margin: 22px 0;
    display: grid;
}

.login_btn {
    background: #f93f44;
    border: 1px solid #eb6865;
    color: #fff;
    padding: 10px 22px;
    font-size: 18px;
    margin: 16px 0 0 0;
    box-shadow: 0px 1px 2px #c0c0c0e0;
    transition: all .3s ease;
}

.login_btn:hover {
    border: 1px solid #eb6865;
    background: none;
    color: #000
}

.otp_btn {
    background: #ffffff;
    border: none;
    color: #444;
    padding: 10px 0px;
    font-size: 17px;
    margin: 15px 3px 15px 3px;
    box-shadow: 0px 1px 3px #85858552;
    display: inline-block;
    width: 48%;
    transition: all .3s ease;
}

.otp_btn:hover {
    background: #f93f44;
    color: #ffffff;
}

.google_btn {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.google_btn a {
    color: #000;
}

.google_btn a:hover {
    color: #f93f44;
}

.google_btn button {
    background: #ce473b;
    border: none;
    padding: 10px 3px;
    width: 42%;
    color: #FFF;
    text-shadow: 0px 1px 0px #666666;
    border-radius: 2px;
    font-size: 16px;
}

.google_btn button:hover {
    opacity: 0.7;
}

.google_btn1 {
    background: #415e9b !important;
    border: none;
    padding: 10px 3px;
    width: 42%;
    color: #FFF;
    text-shadow: 0px 1px 0px #666666;
    border-radius: 2px;
    font-size: 16px;
}

.google_btn1:hover {
    opacity: 0.7;
}

.descri_hedi {
    font-weight: 500;
    font-size: 20px;
    padding: 10px 12px;
    margin: 0 0 12px 0;
    color: #fff;
    text-align: center;
    background: #464a4c;
}

.sign_bg {
    background: #fff;
    margin: 1% 6%;
    display: flow-root;
    border: 1px solid #e6dede;
}

.sign_up {
    margin: 2% 3% 2% 3%;
    padding: 2% 3%;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #464a4c4a;
}

.sign-btn {
    background: #f93f44;
    border: 1px solid #eb6865;
    color: #fff;
    padding: 8px 12px;
    font-size: 17px;
    margin: 10px 0 10px 0;
    box-shadow: 0px 1px 2px #b4b4b4e0;
    transition: all .3s ease;
    border-radius: 50px;
}

.sign-btn:hover {
    border: 1px solid #525252de;
    background: none;
    color: #000
}

.forget_btn {
    background: #ed3237;
    border: 1px solid #ed3237;
    color: #fff;
    padding:7px 22px;
    font-size: 16px;
    margin: 15px 6px;
    box-shadow: 0px 1px 2px #b4b4b4e0;
    transition: all .3s ease;
    border-radius: 2px;
}

.forget_btn:hover {
    border: 1px solid #111111;
    background: none;
    color: #000000;
}

/* login form */
/* BECOME A DISTRIBUTOR */
.become_distr {
    background: #fff;
}

.become_distr h1 {
    font-size: 20px;
    padding: 6px 0;
    border-bottom: 2px solid #5e5e5eeb;
    display: inline-block;
    font-weight: 500;
    margin: 8px 0 14px 0;
}

.dis_para p {
    text-align: left;
    font-size: 16px;
    line-height: 27px;
    padding: 6px 6px;
}

.form-distributor {
    background-color: #fff;
    width: 55%;
    margin: 12px auto;
    border-radius: 4px;
    display: flow-root;
    border: 1px solid #ccc;
}

#form-dis {
    margin: 15px 36px;
}

/* BECOME A DISTRIBUTOR */

/* User Address */
.profile_btn {
    display: flex;
    justify-content: space-evenly;
}

.profile_btn a {
    background: #fff;
    border: 1px solid #eb6865c4;
    outline: none;
    padding:9px 2px;
    font-size: 16px;
    color: #4e4e4e;
    margin: 12px 0 12px 0;
    border-radius: 1px;
    transition: all .5s ease;
    width:135px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
}

.profile_btn a:hover {
    background: #f93f44 !important;
    color: #fff;
}

.add-active {
    border: 1px solid #f93f44 !important;
    background: #f93f44 !important;
    color: #fff !important;
}

.user_address_from {
    margin: 8px 0;
    border: 1px solid rgba(98, 98, 98, 0.4);
    padding: 6px 28px;
    background: #fff;
}

.user_address_from h2 {
    font-size: 22px;
    margin: 3px 0 12px 3px;
    border-bottom: 1px solid rgba(237, 50, 55, 0.48);
    padding: 6px 0 6px 0;
}

.user_heading {
    margin: 9px 0 2px 0;
    font-size: 16px;
    text-shadow: 0 0 #6e6d6d;
}

.add-address-btn {
    color: #ffffff;
    border: 1px solid #FF5722;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 50px;
    background: #FF5722;
}

.add-address-btn:hover {
    background: red;
}

.shipping-add-box {
    margin: 20px 0 12px 0;
    padding: 12px 12px;
    background: #464545de;
    color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.shi-address {
    margin: 4px 0;
    font-size: 15px;
}

.shipping-add-box1 {
    margin: 20px 0 12px 0;
    padding: 12px 12px;
    background: #ffffffde;
    color: rgb(0, 0, 0);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.shi-address1 {
    margin: 4px 0;
    font-size: 15px;
}

.empty-shi {
    margin: 4px 0;
    color: #fff;
    background: #6d6c6c;
    text-align: center;
    padding: 6px 8px;
    border-radius: 50px;
    font-size: 18px;
}

/* User Address */

/* feedback */
.feedback {
    background-color: #f3f3f3;
    margin: 6px 0 0 0;
}

.errMgs {
    color: red;
    font-size: 12px;
    padding: 0 12px;
    font-weight: bold;
}

.feedback_form {
    background-color: #fff;
    width: 68%;
    margin: 18px auto;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
    display: flow-root;
}

.feed-mar {
    padding: 6px 12px !important;
    border: 1px solid #bdbdbd !important;
    margin: 3px 0 3px 0;
    box-shadow: 1px 2px 1px #fa626229;
}

/* feedback */

/* Disclaimer */
.disclaimer {
    text-align: center;
    background: #f5f5f5;
    border-top: 1px solid #79757578;
    margin: 6px 0 0 0;
}

.bg-border {
    border: 1px solid #eee;
}

.discl_bg {
    background: #fff;
    padding: 22px 18px;
}

.discl_bg h1 {
    font-size: 24px;
    padding: 3px 0;
    border-bottom: 2px solid #5e5e5eeb;
    display: inline-block;
    margin: 22px 0 35px 0;
}

.discl_bg h2 {
    font-size: 18px;
    margin: 6px 0;

}

.discl_bg img {
    max-width: 100%;
}

.discl_bg p {
    font-size: 15px;
    line-height: 26px;
    margin: 6px 0px;
    color: #454545;
    text-align: justify;
}

/* Disclaimer  */

/* Order */
.order_boxbg {
    background: #eb68650f;
    padding: 5px 0 28px 0;
    margin: 6px 0 0 0;
}

.order-box {
    margin: 12px 0 0 0;
    padding: 0px 12px;
    height: 98%;
    background: #fff;
    margin-bottom: 22px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.back-ship {
    background: #424242e6;
    padding: 5px 0;
    color: #fff;
}

.order-hedi {
    text-align: left;
    font-size: 20px;
}

.pen-edit {
    text-align: right;
    padding: 3px 0;
}

.pen-edit a {
    color: #fff;
}

.pen-edit a:hover {
    color: #fff;
}

.address-order {
    text-align: left;
    margin: 5px 0;
    font-size: 16px;
}

.order-bold {
    font-weight: bold;
    margin: 3px 0;
    display: inline-block;
}

.right-order-text {
    text-align: left;
    margin: 3px 0;
}

.order-border {
    border-bottom: 1px solid #c4c4c4;
}

.paypros {
    margin: 0 6px;
}

.shoping_btn {
    background: #f93f44;
    border: 1px solid #f93f44;
    color: #fff;
    padding: 8px 2px;
    font-size: 15px;
    margin: 34px 0px 6px 0;
    box-shadow: 0px 1px 2px #b4b4b4e0;
    transition: all .3s ease;
    width: 95%;
}

.shoping_btn:hover {
    border: 1px solid #525252de;
    background: none;
    color: #000
}

.order-list {
    border: 1px solid #aaaaaa;
    ;
    margin: 40px 0;
    padding: 0 14px;
}

.order-list h2 {
    font-size: 22px;
    margin: 16px 8px;
    border-bottom: 1px solid rgb(177, 177, 177);
    padding: 6px 0;
}

.booPrice {
    font-size: 16px;
    color: 000;
    margin: 0 6px 0 0;
    font-weight: bold;
}

.order-rupess {
    font-size: 16px;
}

.linethrow {
    font-size: 17px;
    color: #f95100;
    text-decoration: line-through;
}

.thanku-hedi {
    text-align: center;
    font-size: 22px;
}

.thanksp {
    margin: 0;
    text-align: center;
    font-size: 16px;
}

.thanks-detail {
    margin: 6px 0;
    text-align: left;
}

.thanks-detail h2 {
    font-size: 20px;
    border-bottom: 1px solid #aaa;
    padding: 2px 0 2px 0;
    margin: 2px 0;
}

.address-thx {
    font-size: 16px;
    border-bottom: 1px solid #c4c4c4;
    padding: 3px 0 3px 0;
    margin: 3px 0;
}

.user-order-hedi {
    font-size: 20px;
    margin: 6px 0;
    color: #484848;
}

.user-order-box {
    border: 1px solid #ccc;
    padding: 6px 12px;
    margin: 6px 0px 12px 0px;
    border-radius: 4px;
}

.ordProTxt {
    font-size: 16px;
    font-weight: 600;
    margin: 3px 0;
}

.ordProTitle {
    font-size: 14px;
}

.order-light {
    background: #ededed;
    margin: 6px 0;
    padding: 6px 12px;
    border: 1px solid #ffe5e5;
}

.light-border {
    border-bottom: 1px solid #c7c7c7;
    margin: 3px 0;
}

.main-order-box {
    display: flex;
    margin: 6px 0;
}

.main-order-box1 {
    margin: 6px 0;
}

.main-order-box1 p {
    text-align: justify;
    font-size: 14px;
}

.main-order-box img {
    margin: 0 12px;
    max-width: 100%;
}

/* Order */

/* payment */
.payment-option {
    text-align: center;
    background: #fff;
    min-height: 500px;
}

.payment-option h1 {
    font-size: 22px;
    border-bottom: 2px dotted #ed32378f;
    padding: 12px 0 6px 0;
    margin: 0 0 32px 0;
}

.imgpay img {
    max-width: 32%;
    margin: 6px 0 25px 0;
}

/* payment */

/* bookshop */
.bookshop {
    padding: 28px;
    max-width: 1140px;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid #b7b7b7;
    border-radius: 4px;
}

.bookshop h1 {
    color: #404040;
    font-size: 22px;
    margin: 5px 0 12px 0;
    display: inline-block;
}

.bookshop-from {
    text-align: center;
    margin: 6px 0;
}

.bookshop-from select {
    width: 100%;
    padding: 11px 6px;
    box-sizing: border-box;
    font-size: 1em;
    line-height: 1em;
    color: #0c0c0c;
    font-weight: normal;
    border: 1px solid #6c757d;
    background: #fff;
    border-radius: 4px;
}

.bookshop-from select:focus {
    outline: none;
}

.line {
    border-bottom: 1px solid #909090;
    margin: 32px 0;
}

.bohedi {
    text-align: center;
    color: #000;
    font-size: 20px;
    margin: 8px 0;
}

.bohedi1 {
    color: red;
}

.bookshop-main {
    border: 1px solid #515151;
    margin: 15px 0px;
}

.bookshop-panel {
    background: #464646f0;
    color: #fff;
    text-align: center;
    padding: 4px 0;
    font-size: 19px;
    border-radius: 0px !important;
}

.bookfa {
    font-size: 18px !important;
    background: rgb(0 0 0 / 58%);
    border-radius: 50px;
    width: 32px;
    height: 32px;
    text-align: center;
    color: #ffffff;
    display: inline-block;
    margin: 2px 24px;
    padding: 2px 0;
}

.add {
    text-align: left;
    padding: 2px 0px;
    font-size: 18px;
    color: #000;
    margin: 7px 0;
    display: inline-block;
}

.favi1 {
    background: #e4241ec4;
}

.favi2 {
    background: #5c8b34ba;
}

.margin-auto {
    margin: 0 auto;
}

/* bookshop */

/* InvitationAuthor  */
.invi-authors {
    font-size: 22px;
    text-align: center;
    border-bottom: 1px solid #2929296e;
    margin: 3px 0 22px 0;
    padding: 3px 0 6px 0;
    position: relative;
}

.invi-authors::before {
    bottom: -2px;
    content: "";
    display: inline-block;
    height: 4px;
    left: 48%;
    position: absolute;
    width: 50px;
    background: #f93f44;
}

.box-invi {
    border: 1px solid rgb(200, 200, 200);
    background: #fff;
    text-align: justify;
    padding: 5px 12px;
    border-radius: 4px;
    margin: 10px;
}

.box-invi img {
    max-width: 100%;
}

.box-invi p {
    font-size: 15px;
    line-height: 23px;
}

.invi-num {
    font-size: 15px !important;
}

.box-invi a {
    text-decoration: none;
}

.invitation-form {
    margin: 18px 34px;
    border: 1px solid rgb(200, 200, 200);
    padding: 6px 12px;
}

.invi-bg {
    margin: 2% 2% 2% 2%;
    padding: 1% 3%;
    border-radius: 2px;
    background: #fff;
    border: 1px solid #8181814a;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09), 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}

.invifrom-hedi {
    font-size: 20px;
    margin: 12px 0;
    border-bottom: 1px solid #ef4a4fd6;
    padding: 3px 0 12px 0;
    position: relative;
}

.invifrom-hedi::before {
    bottom: -3px;
    content: "";
    display: inline-block;
    height: 5px;
    left: 18px;
    position: absolute;
    width: 40px;
    background: #ed3237;
}

.year-center {
    margin: 10px 0;
}

.choose-hedi {
    margin: 6px 0;
}

.radio-ui {
    display: inline-block;
    margin: 0 18px;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    float: none;
    margin: 0 12px;
}

.specimen-bg {
    margin: 1% 0% 1% 0%;
    padding: 0% 3%;
    border-radius: 2px;
    background: #fff;
}

/* InvitationAuthor */

/* career page  */
.career-control {
    padding: 6px 8px !important;
    border: 1px solid #939393 !important;
    margin: 6px 0 6px 0;
    border-radius: 0 !important;
    font-size: 15px !important;
}

.career-border {
    border-bottom: 1px solid #b4b4b4ed;
    padding: 6px 6px;
}

.career-btn {
    background: #f93f44;
    border: 1px solid #f93f44;
    box-shadow: 0px 1px 2px #b4b4b4e0;
    transition: all .3s ease;
    margin: 6px 0;
    padding: 6px;
    color: #fff;
    font-size: 16px;
    width: 100%;
}

.career-btn:hover {
    opacity: 0.8;
}

.career-job-hedi {
    font-size: 25px;
    text-align: center;
    border-bottom: 1px solid #b4b4b4ed;
    margin: 8px 0 12px 0;
    padding: 3px 0 8px 0;
    position: relative;
}

.career-job-hedi::before {
    bottom: -2px;
    content: "";
    display: inline-block;
    height: 4px;
    left: 47%;
    position: absolute;
    width: 50px;
    background: #f93f44;
}

.job-profile-hedi {
    text-align: center;
    font-size: 18px;
}

.car-main-hedi {
    margin: 3px 0 3px 0;
    font-size: 16px;
}

.favi-career {
    text-align: center;
    margin: 3px 0 3px 0;
    font-size: 16px;
}

.btn-career {
    background: #f93f44;
    border: 1px solid #f93f44;
    box-shadow: 0px 1px 2px #b4b4b4b0;
    transition: all .3s ease;
    margin: 28px 0 8px 0;
    padding: 5px 22px;
    color: #fff;
    font-size: 15px;
}

.btn-career:hover {
    opacity: 0.7;
}

.car-space {
    margin: 24px 0;
}

/* career page */

/* career detail */
.career-detail-bg {
    border: 1px solid rgb(200, 200, 200);
    background: #fff;
    text-align: justify;
    padding: 12px 10px;
    border-radius: 0px;
    margin: 4px 2px;
}

.career-detail-hedi {
    font-size: 22px;
    padding: 3px 0;
    border-bottom: 2px solid #5e5e5eeb;
    display: inline-block;
    font-weight: 500;
    margin: -2px 0 12px 0;
}

.discri-career {
    font-size: 22px;
    margin: 8px 0;
    border-bottom: 1px solid #c1c1c1;
    padding: 4px 0 3px 0;
}

.detailc-btn {
    border: 1px solid #e8504d;
    margin: 18px 6px;
    box-shadow: 0px 1px 2px #b4b4b4e0;
    transition: all .5s ease;
    padding: 6px 10px;
    color: #f53733;
    font-size: 15px;
    text-align: center;
    background: none;
}

.detailc-btn:hover {
    background: #eb6865;
    color: #fff;
    border: 1px solid #eb6865;
}

.recommed {
    border: 1px solid #d3d3d3;
    padding: 5px 22px 5px 22px;
    margin: 0px 15px 8px 15px;
    box-shadow: 1px 1px 3px #b1b1b18c;
}

/* career detail */

/* Write Review */
.customer-review {
    background-color: #fff;
    width: 68%;
    margin: 22px auto;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
    display: flow-root;
}

.over-rating {
    font-size: 19px;
    margin: 6px 0;
}

.review-star {
    margin: 2px 4px;
    color: #909090;
    font-size: 20px;
}

.review-star:hover {
    color: #de7921;
}

.rate-not {
    color: #666;
    font-size: 14px;
}

.select-star {
    color: rgb(89, 89, 89);
    font-size: 15px;
    margin: 3px 0;
}

.review-success {
    background-color: #d1e7dd;
    border: 1px solid #d0d0d0;
    font-size: 16px;
    margin: 6px 0;
    padding: 9px 12px;
}

.review-empty {
    text-align: right;
    font-weight: 600;
    color: #000;
    cursor: pointer;
}

/* Tracking Details */
.tracking-detail {
    background-color: #fff;
    width: 550px;
    margin: 18px auto;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
    display: flow-root;
}

.track-box {
    margin: 4% 4% 4% 4%;
    padding: 2% 2%;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #464a4c4a;
}

.track-hedi {
    text-align: left;
    padding: 2px 0px;
    font-size: 18px;
    color: #000;
    margin: 4px 6px;
}

/* Tracking Details */
/* Product Catalogue  */
.catalogue-box {
    padding: 14px 0px;
    display: flex;
}

.catalogue_Box1 {
    margin: 8px 6px;
    position: relative;
}

.catalogue-box img {
    width: 100%;
    border: 2px solid #e0e0e0;
    margin: 50px 0 5px 0;
}

.cat-small {
    text-align: center;
    font-size: 18px;
    margin: 3px 0 10px 0;
    transform: translate(0%, -40%);
    position: absolute;
    width: 100%;
    justify-content: center;
    display: flex;
}

.cat-small1 {
    text-align: center;
    font-size: 18px;
    margin: 3px 0 8px 0;
}

.onlyfontswith {
    font-size: 16px;
    color: #000;
    margin: 7px 0;
    background: #f3f3f3 !important;
}

.table> :not(:first-child) {
    border-top: 2px solid #cccccc !important;
}

.colour_red {
    color: #F00;
    font-size: 18px;
}

.rs_line_throu {
    margin: 2px 4px;
    text-decoration: line-through;
}

.cal-quantity {
    width: 60px;
    border-radius: 4px;
    border: 1px solid #CCC;
    padding: 3px 6px;
}

.cal-quantity:focus {
    outline: none;
}

.font-small {
    font-size: 13px;
}

.check_mark {
    position: relative;
}

.checkmark {
    position: absolute;
    top: 22%;
    left: 30%;
    height: 30px;
    width: 31px;
    background-color: #ffffff;
    cursor: pointer;
    border: 1px solid #ccc
}

.checkbox-wrap input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-wrap:hover input~.checkmark {
    background-color: #ccc;
}

.checkbox-wrap input:checked~.checkmark {
    background-color: #2ace59;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-wrap input:checked~.checkmark:after {
    display: block;
}

.checkbox-wrap .checkmark:after {
    left: 12px;
    top: 6px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.catlogMsg {
    margin: 22px 0 0 0;
    text-align: center;
    color: #0d6efd;
}

/* Product Catalogue */
/* Authors */
.content {
    font-size: 16px;
    text-align: justify;
    padding: 18px 10px;
    color: #000;
    line-height: 24px;
}

.content ul li {
    list-style: disc;
}

.main_authors p {
    margin: 6px 0;
}

.collapse-auth {
    color: #005aa9;
    margin: 0 6px;
    font-size: 16px;
    cursor: pointer;
}

.name-auther {
    font-size: 16px;
    color: black;
    margin: 0px 0 6px 0;
}

.img-auther figure {
    position: relative;
    overflow: hidden;
    width: 75%;
    margin: 6px auto;
    border-radius: 50%;
    border: 4px solid #e3e3e3;
    text-align: center;
}

.img-auther figure img {
    width: 100%;
}

.fa.icon-fa {
    font-size: 16px;
    color: red;
    padding: 0 12px 0 0;
}

.img-auther img {
    border-radius: 4px;
    max-width: 100%;
    border-radius: 50%;
}

.main_authors {
    margin: 0px 0 15px 0;
    border: 1px solid #567371;
    background: #fff;
}

.hedi_auther {
    text-align: center;
    font-size: 22px;
    color: #fff;
    padding: 3px 0;
    border-bottom: 1px solid #404040;
    background: #575757f0;
    text-shadow: 2px 2px 1px #231717c2;
}

/* Authors */

/* reviews */
.reviews-section {
    margin: 12px 10px;
    box-shadow: 0px 0 2px #0000007a;
    padding: 8px 12px;
    color: #000;
}

.review-cos {
    font-size: 18px;
    margin: 1px 2px;
    color: #de7921;
}

.stars-display {
    font-size: 28px;
    color: #de7921;
}

.review-hedi {
    font-size: 16px;
    margin: 0 1px;
    color: #000;
}

.reviews-section p {
    font-size: 15px;
    padding: 4px 6px;
    margin: 6px 0;
}

.review-date {
    color: #fd2525;
    font-size: 15px;
}

/* reviews */

/* Pagination css  */
.pagina-outer {
    text-align: center;
}

.pagina {
    background: #FFF;
    padding: 6px 3px;
    position: relative;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 14px 0;
    border: 1px solid #b050506b;
}

.pagina li a.page-link {
    color: #140536;
    background-color: #fff;
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    width: 40px;
    padding: 0;
    margin: 0 12px 0 0;
    border: none;
    cursor: pointer;
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.01), 0 1px 3px rgba(0, 0, 0, 0.48);
    overflow: hidden;
    position: relative;
}

.pagina li.active a.page-link,
.pagina li a.page-link:hover,
.pagina li.active a.page-link:hover {
    color: #fff;
    border: none;
}

.pagina li:first-child a.page-link {
    border-radius: 40px 0 0 40px;
}

.pagina li:last-child a.page-link {
    border-radius: 0 40px 40px 0;
}

.pagina li a.page-link:before {
    content: '';
    background-color: #565656;
    height: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: all 0.3s;
}

.pagina li.active a.page-link:before,
.pagina li a.page-link:hover:before,
.pagina li.active a.page-link:hover:before {
    height: 100%;
}

@media only screen and (max-width: 480px) {
    .pagina {
        padding: 6px;
        margin: 0;
        border-radius: 6px;
        display: block;
    }

    .pagina li {
        display: inline-block;
        margin: 0 0;
    }
}

/* Pagination css   */
/* TERMS & CONDITIONS   */
.condition-box {
    box-shadow: rgb(99 99 99 / 45%) 0px 1px 5px 0px;
    margin: 12px 6px;
    padding: 3px 6px;
}

.term-hedi {
    text-align: center;
    font-size: 15px;
    margin: 0;
    color: #000;
}

.term-hedi a {
    text-decoration: none;
}

.condition-box p {
    font-size: 15px;
    color: #000;
    border: 1px solid #ccc;
    padding: 8px 8px;
    margin: 12px 6px;
    line-height: 22px;
    text-align: justify;
}

.condition-box a {
    text-decoration: none;
}

.blue {
    color: #06f;
}

.policy-hedi {
    font-size: 18px;
    margin: 3px 0;
}

.poli-bold {
    font-size: 15px;
    font-weight: bold;
}

/* TERMS & CONDITIONS   */
/* Faq */
.faqs {
    border: 1px solid #d9d9d9;
    padding: 6px 3px;
    margin: 20px 12px;
}

/* Faq */

/* news events */
.news-back {
    background: #f3f3f36b;
    margin: 6px 0 0 0;
    padding: 5px 0;
}

.news {
    font-size: 15px;
    text-align: justify;
    margin: 12px 8px 22px 8px;
    color: #000;
}

.border-section {
    border: 1px solid #b5b5b5;
    background: #fff;
    padding: 4px 8px;
    border-radius: 2px;
}

.flip-card {
    width: 100%;
    height:15vw;
    overflow: hidden;
    position: relative;
}

.flip-card-inner {
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 2s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    color: black;
    background: #f4f4f4;
}

.flip-card-back {
    color: white;
    transform: rotateY(180deg);
    background: #f4f4f4;
}

.news-box {
    border: 1px solid #706f6f;
    background: #f4f4f4;
    height: 100%;
    position: relative;
}

.flip-card-front img {
    max-width: 100%;
    height: 100%;
}

.flip-card-back img {
    max-width: 100%;
    height: 100%;
}

.news-contant-section {
    margin: 8px 0 42px 0px;
    text-align: center;
}

.news-contant-section p {
    font-size: 15px;
    margin: 2px 4px;
}

.news-contant-section h6 {
    margin: 3px 0;
    font-size: 16px;
    font-weight: bold;
}

.NEDate {
    margin: 0px 3px;
    color: #FF6600;
}

.news-btn {
    border: none;
    background: #575757;
    color: #fff;
    padding: 8px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

/* news events */
/* news events detail  */
.news-detail-con {
    box-shadow: 0px 1px 3px #2828284a;
    margin: 0px 6px;
    padding: 8px 12px;
    font-size: 15px;
    text-align: justify;
}

.news-img-section {
    margin: 4px 2px;
}

.news-img-section img {
    width: 100%;
    height: 310px;
}

.news-banner {
    max-width: 1100px;
    margin: 10px auto;
    border: 1px solid #CCC;
    padding: 10px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 10%);
}

.fix-wh {
    width: 100%;
    max-height: 46vh;
}

.blur-bg {
    background:#222121ab;
    padding:8px 0;
    font-size: 20px;
    width: 92%;
    margin: 0 auto;
}

.blur-bg a {
    color: #fff;
    text-decoration: none;
}

.blur-bg a:hover {
    color: rgb(198, 198, 198);
    text-decoration: underline;
}

/* index popup */
.imgpopup {
    position: relative;
}

.img-close {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    background: none;
    border: none;
    position: absolute;
    top: -2%;
    right: -3%;
    z-index: 9999999;
    background: #ed3237;
    padding: 5px 12px;
    border-radius: 50px;
}

.img-close:hover {
    background: #1b1b1b;
}

.popup-btn-left {
    width: 20%;
    border: 0px solid #87ed44;
    position: absolute;
    bottom: 10%;
    left: 14%;
    padding: 15px;
}

.popup-btn-right {
    width: 20%;
    border: 0px solid #87ed44;
    position: absolute;
    bottom: 10%;
    right: 14%;
    padding: 15px;
}

/* index popup */
/* news events detail  */

/* Order from */
.order-from {
    border: 1px solid #cacaca;
    margin: 0 12px;
    padding: 30px;
    background: #fff;
    text-align: center;
}

.primary-box {
    margin: 22px;
    padding: 30px 6px;
    text-align: center;
    color: rgb(255, 255, 255);
    border: 1px solid #f93f44;
    border-radius: 22px;
    background: #f93f44;
    transition: all .5s ease-in-out;
}

.primary-box:hover {
    transform: scale(1.1);
}

.primary-box h4 {
    margin: 8px 0 58px 0px;
    font-size: 22px;
    color: #fff;
}

.primary-btn {
    border: 1px solid #fff;
    display: inline-block;
    padding: 8px 20px;
    border-radius: 50px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    transition: all .2s ease;
}

.primary-btn:hover {
    background: rgb(59, 59, 59);
}

.togrther-box {
    margin: 22px;
    padding: 30px 6px;
    text-align: center;
    color: rgb(255, 255, 255);
    border: 1px solid #595959;
    border-radius: 22px;
    background: #333333e6;
    transition: all .5s ease-in-out;
}

.togrther-box:hover {
    transform: scale(1.1);
}

.togrther-box h4 {
    margin: 8px 0 58px 0px;
    font-size: 22px;
    color: #fff;
}

.togrther-btn {
    border: 1px solid #fff;
    display: inline-block;
    padding: 8px 20px;
    border-radius: 50px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    transition: all .2s ease;
}

.togrther-btn:hover {
    background: rgb(255, 255, 255);
    color: #333 !important;
}

.display-center {
    justify-content: center;
}

/* Order from */

/* Vridhi page  */
.vridhi-shedi {
    text-align: center;
    border-bottom: 1px solid #d2d2d2;
    padding: 0 0 6px 0;
}

.vridhi-img {
    max-width: 100%;
}

/* Vridhi page  */

/* Wislist */
.box-item a {
    color: #000;
}

.box-item a:hover {
    color: #ed3237;
}

.wislist-main {
    margin: 16px 0 8px 0;
    padding: 6px 12px;
    border: 1px solid #ccc;
    height: 95%;
}

.wislist-main a {
    color: #000;
    transition: all .3s ease 0s;
}

.wislist-main a:hover {
    color: #4a6ccd;
}

.wislist-btn {
    background: #5e5e5e;
    border: 1px solid #5e5e5e;
    color: #fff;
    padding: 6px 14px;
    border-radius: 50px;
    margin: -4px 0;
    font-size: 14px;
}

/* Wislist */

/* pdf image */
.pdf-posi {
    position: relative;
}

.pdf-img img {
    max-width: 11% !important;
    cursor: pointer;
    margin: 0 12px;
}

/* .pdf-img {
    position: absolute;
    top: 4%;
    right:33% ;
} */
.borderB1 {
    /* border-bottom: 1px solid #d0caca; */
    margin: 2px 0;
    padding: 2px 3px 4px 3px;
}

/* pdf image */

/* celebration Year */
.celebration-year {
    margin: 12px 2px;
    padding: 20px 6px;
    text-align: center;
    color: rgb(255, 255, 255);
    border: 1px solid #516374;
    border-radius: 22px;
    background: #48535e;
    transition: all .5s ease-in-out;
}

.celebration-year h4 {
    margin: 12px 0px;
    font-size: 20px;
    color: #fff;
}

.celebration-year h5 {
    margin: 8px 0;
    font-size: 18px;
}

.celebration-btn {
    border: 1px solid rgb(232, 232, 232);
    display: inline-block;
    padding: 8px 16px;
    border-radius: 50px;
    font-size: 15px;
    color: #fff;
    cursor: pointer;
    transition: all .2s ease;
    margin: 15px 0 15px 0;
}

.celebration-btn:hover {
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
}

.celYear1 {
    margin: 12px 2px;
    padding: 20px 6px;
    text-align: center;
    color: rgb(255, 255, 255);
    border: 1px solid #eb6865;
    border-radius: 15px;
    background: #d55e5b;
    transition: all .5s ease-in-out;
}

.celYear1 h4 {
    margin: 12px 0px;
    font-size: 20px;
    color: #fff;
}

.celYear1 h5 {
    margin: 8px 0;
    font-size: 18px;
}

.celYear1-btn {
    border: 1px solid #fff;
    display: inline-block;
    padding: 8px 16px;
    border-radius: 50px;
    font-size: 15px;
    color: #fff;
    cursor: pointer;
    transition: all .2s ease;
    margin: 15px 0 15px 0;
}

.celYear1-btn:hover {
    background: rgb(59, 59, 59);
    color: #fff;
}

/* celebration Year  */

/* catloage cart */
.catalogue_cart {
    margin: 5px 12px;
    position: relative;
    position: fixed;
    top: 50%;
    right: -12px;
    border: 1px solid #ddd;
    display: inline-block;
    padding: 6px 12px;
    box-shadow: 0 12px 6px -13px #1f1f1f;
    background: #fff;
}

.addTo_icon {
    color: #eb6865;
    font-size: 20px;
    display: inline-block;
}

.catalogue_count {
    position: absolute;
    bottom: 26px;
    background: #393939;
    padding: 2px 0px;
    color: #fff;
    border-radius: 50%;
    left: -9px;
    font-size: 14px;
    display: none;
    width: 26px;
    text-align: center;
    z-index: 999;
}

/* catloage cart  */

.blinkPreOrder {
    font-size: 20px;
    color: #f95100;
    font-weight: bold;
    animation: animate 1.5s linear infinite;
}

@keyframes animate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.order_primary {
    border: 1px solid #b1b1b1;
    margin: 12px 6px;
    padding: 25px 12px;
    background: #fff;
    text-align: left;
    border-radius: 0px;
    box-shadow: 0px 0px 4px 0px rgb(89 89 89 / 62%);
}

.primary-from label {
    margin: 2px 0;
    color: rgb(66, 66, 66);
}

.primary-from input {
    border: 1px solid #b7b7b7;
    padding: 7px 12px;
    margin: 6px 0 12px 0;
    color: #333;
    font-size: 14px;
}

.together-hedi {
    text-align: center;
    margin: 15px 0 15px 0;
    color: #fff;
    border: 1px dotted #212529;
    padding: 6px 0;
    font-size: 18px;
    background: #5e5e5eed
}

.togrther-box a {
    color: #fff;
}

.togrther-box a:hover {
    color: #fff;
}

.primary-box a {
    color: #fff;
}

.primary-box a:hover {
    color: #fff;
}

/*  */

/* book filter */
.filter-bg {
    background: #f2f2f21c;
    border: 1px solid #b0b0b0;
    padding: 6px 6px;
    margin-top: 5% !important;
}

.filtername {
    margin: 0 12px;
    font-size: 14px;
}

.left-side-bar {
    border: 1px solid #d2d2d2;
    padding: 4px 6px 3px 6px;
    display: flex;
    margin: 8px 0;
}

.down-font {
    font-size: 12px;
    padding: 6px 12px;
}

.search-bar-hedi {
    font-size: 15px;
    color: #000;
    justify-content: space-between;
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid #c6c6c6;
    margin: 4px 0;
    padding: 3px 0;
}

.priceRange {
    margin: 10px 0 0 0;
}

.filterClick {
    position: relative;
}

.filtericon {
    position: absolute;
    top: 40%;
    right: 6%;
    font-size: 22px;
    color: #fff;
    box-shadow: 0px 1px 1px #979797;
    padding: 4px 14px;
    background: #222222b3;
    border-radius: 4px;
    cursor: pointer;
}

/* book filter */
/* invioce */
.invioce-section {
    background: #fff;
    padding: 6px 12px;
    box-shadow: rgb(99 99 99 / 18%) 0px 2px 8px 0px;
}

.invioce-section a {
    color: #333;
}

.invioce-section a :hover {
    color: #333;
}

.favitruck {
    font-size: 25px;
    margin: 0 8px 0 0;
    color: #e34844;
}

.favipdf {
    font-size: 22px;
    margin: 8px 6px;
    color: #eb6865;
    font-weight: 700;
}

.delived {
    font-size: 20px;
}

.small-hedi {
    font-size: 18px;
    color: #6e5d5d;
}

.invioce-shipping {
    margin: 18px 0 0 0;
}

.invioce-shipping h5 {
    color: #454545;
    font-size: 19px;
}

.invioce-section img {
    max-width: 100%;
    height: 137px;
}

.order-address {
    background: #fff;
    border: 1px solid #b1b1b1;
    /* padding: 0 12px; */
}

.order-placeBg {
    background: #f3f3f3;
    padding: 6px 10px;
    margin: 0;
    font-size: 15px;
}

.order-white {
    background: #fff;
    padding: 2px 4px;
    margin: 0 3px;
}

.payment-section {
    padding: 2px 10px;
}

.payment-section h3 {
    font-size: 17px;
    margin: 6px 0;
    font-weight: 600;
    padding: 0 0 5px 0;
    border-bottom: 1px solid #b27575;
}

.paytem-detail {
    font-size: 14px;
    color: #333;
    padding: 0px 0;
}

.order-price {
    text-align: right;
    font-size: 14px;
}

.totalBorder {
    border-bottom: 1px solid #b27575;
    padding: 2px 0;
    margin: 0 0 15px 0;
}

.address-order {
    font-size: 15px;
    margin: 0 0;
    padding: 6px 0;
}

/* invioce */

/* Principal Biography */
.bioGraphy-main {
    width: 81% !important;
    margin: 0 auto;
}

.bioGraphy {
    font-size: 22px;
    text-align: center;
    border-bottom: 1px solid #4141416e;
    margin: 4px 0 10px 0;
    padding: 2px 0 6px 0;
    position: relative;
    font-weight: bold;
}

.bioGraphy::before {
    bottom: -3px;
    content: "";
    display: inline-block;
    height: 4px;
    left: 47%;
    position: absolute;
    width: 50px;
    background: #ed3237e0;
}

.biography-hedi {
    font-size: 20px;
    margin: 6px 0 8px 0;
    font-weight: bold;
    color: #ed3237;
    text-transform: uppercase;
}

.box-shadow {
    box-shadow: 3px 3px 10px rgb(0 0 0 / 28%);
    padding: 10px 12px;
    border-radius: 2px;
    margin: 12px 0 12px 0;
    background: #ffff;
    position: relative;
}

.box-shadow p {
    font-size: 16px;
    text-align: justify;
    color: #000;
}

.bioGraphy-para {
    font-size: 16px !important;
    text-align: justify;
    color: #000;
}

.bio-btn {
    background: #5e5e5e;
    color: #fff;
    padding: 10px 18px;
    margin: 2px 6px;
    border-radius: 50px;
    font-size: 15px;
    display: inline-block;
    transition: all 0.35s;
    position: absolute;
    bottom: 9px;
    right: 6px;
}

.bio-btn:hover {
    color: #fff;
    background: #ed3237;
}

.biography {
    animation: mover 3s infinite alternate;
}

@keyframes mover {

    0%,
    to {
        transform: translateY(-5px)
    }

    50% {
        transform: translateY(5px)
    }
}

/* Principal Biography */

/* Ebook popup */
.ebookPopup {
    width: 720px;
    margin: 3px 6px;
    background: #fff;
    text-align: left;
    position: relative;
    animation: fadeInUp 1s ease backwards;
}

@keyframes fadeInUp {
    0% {
        transform: translate(0px, 100px);
        opacity: 0;
    }

    100% {
        transform: translate(0px, 0px);
        opacity: 1;
    }
}

.ebookPopup ul {
    margin: 10px 4px;
    font-size: 17px;
    letter-spacing: 1px;
    list-style: disc;
    margin: 12px 0;
}

.ebookheader {
    padding: 1px 12px !important;
}

/* Ebook popup */

/* Reward Point */
.reward-section {
    text-align: center;
    background: #f7f7f7;
    padding: 6px 0;
    margin: 10px 0;
}

.balance-circle {
    border: 2px solid red;
    width: 90px;
    margin: 8px auto;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffffff;
    font-size: 25px;
    color: #000000;
    font-weight: bold;
}

.reward-box {
    margin: 8px 0px;
    background: #f7f7f7;
    padding: 8px 12px;
    border-left: 4px solid #82cf0e;
    border-radius: 2px;
}

.reward-box-plus {
    margin: 8px 0px;
    background: #f7f7f7;
    padding: 8px 12px;
    border-left: 4px solid #82cf0e;
    border-radius: 2px;
}

.reward-box-minus {
    margin: 8px 0px;
    background: #f7f7f7;
    padding: 8px 12px;
    border-left: 4px solid #FF8C00;
    border-radius: 2px;
}

.rewardborder {
    border-left: 4px solid #FF8C00;
}

.reward-plus {
    font-size: 22px;
    color: #82cf0e;
}

.reward-minus {
    font-size: 22px;
    color: #FF8C00;
}

.reward-width {
    width: 45%;
    border-top: 2px solid #cecece;
    display: inline-block;
    margin: 0 6px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #ffffff !important;
    background: #000000ab !important;
    border-radius: 0px !important;
}

.nav-tabs .nav-link {
    margin: 0 2px !important;
    border: 1px solid #c9c9c9 !important;
    border-radius: 0 !important;
    color: #333 !important;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6 !important;
    padding: 6px 6px !important;
}

/* Reward Point  */

/* dynimic */
.dynamic-section {
    margin: 10px 0;
    background: #f3f3f36b;
    padding: 3px 0;
}

.dynamic-section h1 {
    font-size: 21px;
    margin: 10px 0 10px 0;
    font-weight: bold;
    color: #000;

}

.dynamic-section h4 {
    font-size: 18px;
    color: #000;
    text-align: justify;
    line-height: 25px;
    letter-spacing: 1px;
}

.dynamic-box {
    background: #fff;
    padding: 12px 15px;
    border: 1px solid #ccc;
    margin: 3px 0;
}

.fa-errow {
    display: flex;
    justify-content: left;
    margin: 12px 0;
    font-size: 18px;
}

.favi-space {
    margin: 0 12px 0 0;
    font-size: 22px;
    color: rgb(0, 0, 0);
}

.fa-errow a {
    color: blue;
}

.get-Started-btn {
    background: #ff7062;
    width: 140px;
    margin: 0 auto;
    padding: 11px 6px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: all .5s ease 0s;
}

.get-Started-btn:hover {
    background: #2b2b2b;
    color: #fff;
}

.dynamic-box p {
    font-size: 18px;
    margin: 10px 2px;
    text-align: left;
    line-height: 25px;
    color: #000;
    letter-spacing: 1px;
}

.sample-paper-box {
    box-shadow: 0px 1px 4px 1px #33333338;
    margin: 8px 0;
    padding: 6px;
}

.sample-paper-box h3 {
    font-size: 20px;
    margin: 6px 0;
    font-weight: 600;
}

.sample-paper-box a {
    padding: 0 12px;
    color: blue;
}

.sample-paper-box .sample-border {
    border-bottom: 1px solid #bbb;
    padding: 3px 0 7px 0;
    margin: 6px 3px;
}

/* dynamic */

/* FrequentBook */
.frequently_book {
    margin: 12px 0;
}

.frequently_book h3 {
    font-size: 24px;
    margin: 0 0 12px 0;
    padding: 12px 0 5px 0;
    border-bottom: 1px solid #aeaeae;
}

.frequently-box {
    background: #ececec;
    /* height: 100%; */
    padding: 10px 12px;
    width: 100%;
    border-radius: 6px;
}

.frequently-box img {
    max-width: 100%;
}

.frequently-Plus {
    display: flex;
    align-items: center;
    font-size: 25px;
    height: 70%;
    justify-content: center;
}

.frequently_book h4 {
    font-size: 14px;
    margin: 3px 0;
    justify-content: center;
}

.frequently_book .price {
    font-size: 15px;
    font-weight: bold;
    padding: 2px 3px;
}

.frequently_book .totalprice {
    margin: 18% 0 2% 0;
    text-align: center;
}

/* FrequentBook */