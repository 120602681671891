.arror-bg {
  position: fixed;
  background: rgba(0, 0, 0, 0.44);
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}
.main-arror{
  width:500px;
  margin: 5px 8px;
  text-align: center;
  box-shadow: 6px 18px 18px rgb(0 0 0 / 8%), -6px 18px 18px rgb(0 0 0 / 8%);
  background: #fff;
  height:350px;
}
.main-arror h1 {
  margin: 15% 0 3% 0 !important;
  font-size: 24px !important;
}
.errow-arror {
  font-size: 44px;
  margin: 4% 0;
}
.arror-contant {
  display: flex;
  justify-content: center;
}
.arror-btn {
  background: #f95753;
  border: 1px solid #f95753;
  color: #fff;
  padding: 7px 0;
  font-size: 18px;
  margin: 28px 6px;
  box-shadow: 0px 1px 2px #b4b4b4e0;
  transition: all .5s ease;
  border-radius: 50px;
  width: 26%;
}
.arror-btn:hover{
  background: rgb(255, 255, 255);
  border: 1px solid #f95753;
  color: #f95753;
}